var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-bg-variant": "light",
        "header-text-variant": "dark",
        "no-close-on-backdrop": "",
        title: _vm.title,
        visible: _vm.show,
        "ok-title": _vm.labelOk,
        "content-class": "shadow",
      },
      on: { ok: _vm.modalOk, hidden: _vm.modalOk },
    },
    [
      _c("div", { staticClass: "d-block" }, [_vm._v(_vm._s(_vm.msg))]),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn mr-1 btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.modalOk },
            },
            [_vm._v("Ok")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }