var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "gantt-import-mapper-header-container",
      staticClass: "gantt-import-mapper-header-container",
    },
    [
      _c("div", { staticClass: "min-height-17" }, [
        _vm.canChangeDirection()
          ? _c(
              "span",
              [
                _c("b-popover", {
                  attrs: {
                    target: `HEADER_DIRECTION_${_vm.params.column.colId}`,
                    placement: "top",
                    triggers: "hover",
                    content:
                      _vm.direction() === "h"
                        ? _vm.$t("task.row")
                        : _vm.$t("task.column"),
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn-action",
                    attrs: {
                      id: `HEADER_DIRECTION_${_vm.params.column.colId}`,
                    },
                    on: { click: _vm.toggleDirection },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: {
                        icon:
                          _vm.direction() === "h"
                            ? ["far", "arrows-left-right"]
                            : ["far", "arrows-up-down"],
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("span", [_vm._v("   ")]),
        _vm._v(" " + _vm._s(_vm.displayValue) + " "),
      ]),
      _c(
        "div",
        {
          ref: "gantt-import-mapper-multiselect-wrapper",
          staticClass: "mt-1 d-inline-block w-80",
        },
        [
          _c("multiselect", {
            ref: "gantt-import-mapper-multiselect",
            staticClass:
              "custom-dropdown-options gantt-import-mapper-cell-header enable-option-icon fit-label-fix fit-content-fix",
            class: _vm.multiselectId,
            style: { "--custom-multiselect-max-width": _vm.maxWidth },
            attrs: {
              "max-height": 1000,
              options: _vm.sanitizedOpts,
              "track-by": "value",
              label: "text",
              placeholder: "",
              searchable: false,
              "allow-empty": false,
              showLabels: false,
            },
            on: { input: _vm.onChange, open: _vm.onSelectOpened },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (props) {
                  return [
                    _vm.isGroupHeader(props.option)
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "option__title option__group_header",
                            },
                            [_vm._v(_vm._s(_vm.getOptionLabel(props.option)))]
                          ),
                        ]
                      : [
                          _vm.valueObj.value == props.option.value
                            ? _c("font-awesome-icon", {
                                staticClass: "selected-option-icon",
                                attrs: { icon: ["far", "check"] },
                              })
                            : _vm._e(),
                          _vm.isGroupOption(props.option)
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "option__title option__group_option",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getOptionLabel(props.option))
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _c("span", { staticClass: "option__title" }, [
                                  _vm._v(
                                    _vm._s(_vm.getOptionLabel(props.option))
                                  ),
                                ]),
                              ],
                        ],
                  ]
                },
              },
            ]),
            model: {
              value: _vm.valueObj,
              callback: function ($$v) {
                _vm.valueObj = $$v
              },
              expression: "valueObj",
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        [
          _vm.canParent() || (_vm.direction() === "v" && _vm.value !== null)
            ? _c("b-popover", {
                attrs: {
                  target: `HEADER_PARENT_${_vm.params.column.colId}`,
                  placement: "top",
                  triggers: "hover",
                  content: _vm.$t("task.select_parent"),
                },
              })
            : _vm._e(),
          _vm.canParent() || (_vm.direction() === "v" && _vm.value !== null)
            ? _c(
                "button",
                {
                  staticClass: "btn-action",
                  attrs: { id: `HEADER_PARENT_${_vm.params.column.colId}` },
                  on: { click: _vm.selectParent },
                },
                [
                  _c("font-awesome-icon", {
                    class: _vm.hasParent() ? "active" : "",
                    attrs: { icon: ["far", "sitemap"] },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        [
          _vm.canOwn() && _vm.direction() === "h"
            ? _c("b-popover", {
                attrs: {
                  target: `HEADER_OWNER_${_vm.params.column.colId}`,
                  placement: "top",
                  triggers: "hover",
                  content: _vm.owned()
                    ? _vm.$t("task.manual_link")
                    : _vm.$t("task.auto_link"),
                },
              })
            : _vm._e(),
          _vm.canOwn() && _vm.direction() === "h"
            ? _c(
                "button",
                {
                  staticClass: "btn-action",
                  attrs: { id: `HEADER_OWNER_${_vm.params.column.colId}` },
                  on: { click: _vm.selectOwner },
                },
                [
                  _c("font-awesome-icon", {
                    class: _vm.owned() ? "active" : "",
                    attrs: { icon: ["far", "link"] },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }