<template>

  <!-- This div class is required to resolve the weird behaviour caused by dhtmlx layout -->
  <div class="gantt-import-mapper" :class="{ dBlock: show }">
    <b-modal 
      :title="getTitle"
      content-class="shadow"
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
      :visible="show"
      @hidden="modalCancel"
      modal-class="gantt-import-mapper-modal"
      >

      <div class="import-controls">
        <multiselect v-model="currentSheet" class="custom-dropdown-options gantt-import-mapper-sheet enable-option-icon"
          :max-height="300"
          :options="sheetNames().map(i => i.value)"
          :custom-label="getSheetNameOptionLabel"
          :placeholder="''"
          :searchable="false" 
          :allow-empty="false"
          :showLabels="false">
          <template slot="option" slot-scope="props">
            <font-awesome-icon class="selected-option-icon" v-if="currentSheet == props.option" :icon="['far', 'check']" />
            <span class="option__title">{{ getSheetNameOptionLabel(props.option) }}</span>
          </template>
        </multiselect>
        
        <span>
          <button id="IMPORT_MAPPER_SHOW_SETTING" class="btn-action" @click="showSettings">
            <font-awesome-icon icon="cog"/>
          </button>
          <b-popover target="IMPORT_MAPPER_SHOW_SETTING" triggers="hover" placement="top">
            {{ $t('button.settings') }}
          </b-popover>
        </span>
        <span>
          <button id="IMPORT_MAPPER_AUTO_SELECT" class="btn-action" @click="autoSelect(false, true)">
            <font-awesome-icon :icon="['far', 'border-all']"/>
          </button>
          <b-popover target="IMPORT_MAPPER_AUTO_SELECT" triggers="hover" placement="top">
            {{ $t('button.auto_select') }}
          </b-popover>
        </span>
        <span>
          <button id="IMPORT_MAPPER_SELECT_NONE" class="btn-action" @click="selectNone()">
            <font-awesome-icon :icon="['far', 'border-none']"/>
          </button>
          <b-popover target="IMPORT_MAPPER_SELECT_NONE" triggers="hover" placement="top">
            {{ $t('button.select_none') }}
          </b-popover>
        </span>
      </div>
      
      
      <ag-grid-vue
        style="width: 100%;"
        class="mt-2 ag-theme-balham mapper-height"
        id="myGrid"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :columnDefs="columnDefs"
        :context="context"
        :defaultColDef="defaultColDef"
        :enableRangeSelection="true"
        :enableRangeHandle="true"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :headerHeight="90"
        :rowData="rowData"
        :suppressContextMenu="true"
        suppressMultiSort
        @range-selection-changed="handleDebouncedRangeSelectionChanged"></ag-grid-vue>
        
      <template v-slot:modal-footer="{  }">
        <b-button :disabled="!canPreview" size="sm" variant="success" @click="modalPreview">
          Preview      
          <div class="d-inline-block mh-50" v-if="preparing">
            <b-spinner small label="Loading..."></b-spinner>
          </div>
        </b-button>
        <b-button  size="sm" variant="danger" @click="modalCancel">
          Cancel
        </b-button>
      </template>
      <div v-if="submitting" class="submit-overlay"><b-spinner class="spinner" variant="primary" label="Loading..."></b-spinner></div>
    </b-modal>

    <ImportDocSettingsModal :show.sync="showSettingsModal" :settings="settings" :properties="properties" :title="importSettingsTitle" @success="settingsChanged"/>
    
    <GanttImportMapperPreviewDialog
      @modal-ok="modalPreviewOk"
      @modal-cancel="modalPreviewCancel"
      :source="items"
      :fields="previewFields"
      :customFields="customFields"
      :staffs="staffs"
      :skills="skills"
      :resources="resources"
      :projectId="projectId"
      :requiredFields="requiredFields"
      :existingData="existingData"
      :mode="mode"
      :properties="properties"
      :show.sync="state.previewShow"
      @stages="onStages"/>

    <GanttErrorDialog
      @modal-ok="errorDialogOk"
      :msg="errorMsg"
      :show="state.errorDialogShow" />
      
    <b-modal :title="$t('task.select_parent')"
        v-model="selectParentShow"
        @ok="selectParentOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <label>{{$t('task.select_parent_field', [treeTitle()])}}</label>
      <TaskTree height="250px" :noEdit="true" :taskData="taskData" :selectedItem="editParentTask" :title="$t('task.summary_tasks')" :staffs="staffs" :skills="skills" :resources="resources" :multiple="false" @selected="onTaskSelected"/>
      <b-form-checkbox class="parent-apply-to-all" v-model="applyToAll">{{ $t('apply_to_all') }}</b-form-checkbox>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.select_owner')"
        v-model="selectOwnerShow"
        @ok="selectOwnerOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <label>{{$t('task.select_owner_field', [treeTitle()])}}</label>
      <TaskTree height="250px" :noEdit="true" :taskData="taskData" :selectedItem="editOwnerTask" :title="$t('task.title_selector')" :staffs="staffs" :skills="skills" :resources="resources" :multiple="false" @selected="onOwnerSelected"/>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.apply_settings')"
        v-model="applySettingsShow"
        @ok="applySettingsOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <label>{{$t('task.apply_settings_body')}}</label>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  //Vendor (Script) Library
  import { cloneDeep } from "lodash";
  import debounce from 'lodash/debounce';
  
  import 'ag-grid-enterprise';
  import { AgGridVue } from 'ag-grid-vue';
  import PlainTooltip from '@/components/Aggrid/Tooltip/PlainTooltip';
  
  import { spreadsheetRaw } from "../script/GanttImportMapperDialog/spreadsheetraw.js"

  //Vue component
  import GanttImportMapperPreviewDialog from "./GanttImportMapperPreviewDialog.vue"
  import GanttErrorDialog from "./GanttErrorDialog"

  import SettingsHeaderComponent from '@/components/Aggrid/CellHeader/Settings';
  import ImgOrTextCellRenderer from '@/components/Aggrid/CellRenderer/ImgOrTextCellRenderer';
  
  import ImportDocSettingsModal from '@/components/modal/ImportDocSettingsModal';
  import TaskTree from '@/components/Task/TaskTree';
  import Multiselect from 'vue-multiselect';

  import { importDocProfileService, staffService, skillService, rebateService, 
    resourceService, fileService, managementService, projectService, 
    companyService, departmentService, locationService,
    customerService, stageService } from '@/services';
  import { getStaffEmailInUse, getTagInUse } from "@/helpers"
  import { getCustomFieldInfo } from '@/helpers/custom-fields'
  import { countryCodes } from '@/selectOptions';
  
  const nextChar = c=>c?String.fromCharCode(c.charCodeAt(0)+1):'A';
  const nextCol = s=>s.replace(/([^Z]?)(Z*)$/, (_,a,z)=>nextChar(a) + z.replace(/Z/g,'A'));
  
  export default {
    name: "GanttImportMapperDialog",
    components: {
      GanttImportMapperPreviewDialog,
      GanttErrorDialog,
      'ag-grid-vue': AgGridVue,
      ImportDocSettingsModal,
      TaskTree,
      Multiselect,
      //aggrid cell renderer/editor/header component
      /* eslint-disable vue/no-unused-components */
      plainTooltip: PlainTooltip,
      settingsHeaderComponent: SettingsHeaderComponent,
      imgOrTextCellRenderer: ImgOrTextCellRenderer
      /* eslint-enable vue/no-unused-components */
    },
    props: {
      projectId: { type: String, default: null },
      show:   { type: Boolean, default: false },          // Toggle true to show ImportMapper dialog, or false to hide it.
      title:  { 
        type: String, 
        default: "Import Document ${filename-holder}" 
      },                                                  // ImportMapper Dialog title.
      source: { type: File, default: null },              // File object of import content.
      mode: { type: String, default: 'TASKS' },
      properties: { type: Array, default: null },          // Properties the user can choose
      googleSheets: { type: Boolean, default: false },
      existingData: { type: Array, default: null }
    },
    data() {
      return {
        state: {
          previewShow: false, // Toggle true to show ImportMapperPreview dialog, or false to hide it.
          errorDialogShow: false
        },
        sheetOptions: [],     // Available sheet of import (Excel) content. Will be used for combobox options.
        currentSheet: null,
        filename: null,
        fileType: null,
        taskThreshold: 1000,
        mappedValues: {},
        items: [], // for preview
        previewFields: [], // columns for preview
        customFields: [], // for preview
        map: {
          count: null,
          desc: null,
          name: null,
          notes: null,
          type: null, 
          startdate: null, 
          enddate: null, 
          duration: null, 
          priority: null, 
          progress: null, 
          schedulemode: null, 
          constraint: null, 
          constraintTime: null,
          fixedcost: null, 
          fixedduration: null,
          currency: null
        },
        startRow: 0,
        endRow: -1,
        headerRow: -1,
        // ag grid
        gridOptions: null,
        columnDefs: [],
        gridApi: null,
        autoGroupColumnDef: null,
        context: null,
        defaultColDef: null,
        rowData: [],
        
        showSettingsModal: false,
        settings: {
          'summary_name': [{ 'name': 'shot', weight: 0}],
          'summary_desc': [{ 'name': 'desc', weight: 0}],
          'summary_count': [{ 'name': 'count', weight: 0}],
          'summary_notes': [{ 'name': 'notes', weight: 0}],
          //'summary_startdate': [{ 'name': 'startdate', weight: 0}], 
          //'summary_enddate': [{ 'name': 'enddate', weight: 0}],
          //'summary_duration': [{ 'name': 'duration', weight: 0}], 
          'summary_priority': [{ 'name': 'priority', weight: 0}], 
          //'summary_progress': [{ 'name': 'progress', weight: 0}], 
          //'summary_schedulemode': [{ 'name': 'schedulemode', weight: 0}], 
          //'summary_constraint': [{ 'name': 'constraint', weight: 0}],
          //'summary_constraintTime': [{ 'name': 'constraintTime', weight: 0}],
          'summary_fixedcost': [{ 'name': 'fixedcost', weight: 0}],
          'summary_fixedduration': [{ 'name': 'fixedduration', weight: 0}],
          'summary_currency': [{ 'name': 'currency', weight: 0}],
          //'summary_workflow_stage': [{ 'name': 'workflow stage', weight: 0}],
          'summary_complexity': [{ 'name': 'complexity', weight: 0}],
          'summary_rebates': [{ 'name': 'rebates', weight: 0}],
          'summary_skills': [{ 'name': 'skills', weight: 0}],
          'summary_staffs': [{ 'name': 'staffs', weight: 0}],
          'summary_resources': [{ 'name': 'resources', weight: 0}],
          'milestone_name': [],
          'milestone_desc': [],
          'milestone_count': [{ 'name': 'count', weight: 0}],
          'milestone_notes': [{ 'name': 'notes', weight: 0}],
          'milestone_startdate': [{ 'name': 'startdate', weight: 0}], 
          //'milestone_enddate': [{ 'name': 'enddate', weight: 0}],
          //'milestone_duration': [{ 'name': 'duration', weight: 0}], 
          'milestone_priority': [{ 'name': 'priority', weight: 0}], 
          'milestone_progress': [{ 'name': 'progress', weight: 0}], 
          'milestone_schedulemode': [{ 'name': 'schedulemode', weight: 0}], 
          'milestone_constraint': [{ 'name': 'constraint', weight: 0}],
          'milestone_constraintTime': [{ 'name': 'constraintTime', weight: 0}],
          'milestone_fixedcost': [{ 'name': 'fixedcost', weight: 0}],
          'milestone_currency': [{ 'name': 'currency', weight: 0}],
          'milestone_workflow_stage': [{ 'name': 'workflow stage', weight: 0}],
          'milestone_complexity': [{ 'name': 'complexity', weight: 0}],
          'milestone_rebates': [{ 'name': 'rebates', weight: 0}],
          'milestone_skills': [{ 'name': 'skills', weight: 0}],
          'milestone_staffs': [{ 'name': 'staffs', weight: 0}],
          'milestone_resources': [{ 'name': 'resources', weight: 0}],
          'count': [{ 'name': 'count', weight: 0}],
          'name': [{ 'name': 'name', weight: 0}],
          'desc': [{ 'name': 'desc', weight: 0}],
          'notes': [{ 'name': 'notes', weight: 0}],
          'type': [{ 'name': 'type', weight: 0}],
          'startdate': [{ 'name': 'startdate', weight: 0}], 
          'enddate': [{ 'name': 'enddate', weight: 0}],
          'duration': [{ 'name': 'duration', weight: 0}], 
          'priority': [{ 'name': 'priority', weight: 0}], 
          'progress': [{ 'name': 'progress', weight: 0}], 
          'schedulemode': [{ 'name': 'schedulemode', weight: 0}], 
          'constraint': [{ 'name': 'constraint', weight: 0}],
          'constraintTime': [{ 'name': 'constraintTime', weight: 0}],
          'fixedcost': [{ 'name': 'fixedcost', weight: 0}],
          'fixedduration': [{ 'name': 'fixedduration', weight: 0}],
          'currency': [{ 'name': 'currency', weight: 0}],
          'workflow_stage': [{ 'name': 'workflow stage', weight: 0}],
          'complexity': [{ 'name': 'complexity', weight: 0}],
          'rebates': [{ 'name': 'rebates', weight: 0}],
          'skills': [{ 'name': 'skills', weight: 0}],
          'staffs': [
            { 'name': 'staffs', weight: 0},
            { 'name': 'staff', weight: 0}
          ],
          'resources': [{ 'name': 'resources', weight: 0}],
          'task_path': [],
          'image': [{ 'name': 'Image', weight: 0}],
          'project': [{ 'name': 'Project', weight: 0}]
        },
        
        tables: [],
        detectTable: 0,
        
        selectParentShow: false,
        selectOwnerShow: false,
        applySettingsShow: false,
        taskData: [],
        editParentTask: null,
        editOwnerTask: null,
        summaryTasks: {},
        ownerTasks: {},
        directions: {},
        canPreview: false,
        staffs: {},
        projects: {},
        skills: {},
        rebates: {},
        stages: [],
        resources: {},
        companies: {},
        locations: {},
        customers: {},
        departments: {},
        preparing: false,
        colours: [
          'var(--import-bg-1)','var(--import-bg-2)','var(--import-bg-3)','var(--import-bg-4)','var(--import-bg-5)',
          'var(--import-bg-6)','var(--import-bg-7)','var(--import-bg-8)','var(--import-bg-9)'],
        submitting: false,
        schema: null,
        requiredFields: [],
        headerComponentOptions: null,
        applyToAll: false,
        enumList: {}
      }
    },
    computed: {
      getTitle() {
        return this.title.replace("${filename-holder}", `- ${this.filename?this.filename:""}`);
      },
      errorMsg() {
        return `Action Aborted. Number of shot and/or asset hits ${this.taskThreshold} threshold.`;
      },
      overlayLoadingTemplate() {
        return `<span class='grid-overlay'>${ this.$t('document.grid.loading') }</span>`;
      },
      importSettingsTitle() {
        const mode = this.mode;  
        if (mode === "TASKS") {
          return this.$t('task.import_settings.title');
        }
        else if (mode === "COMPANY") {
          return this.$t('company.import_settings.title');
        }
        else if (mode === "DEPARTMENT") {
          return this.$t('department.import_settings.title');
        }
        else if (mode === "STAFF") {
          return this.$t('staff.import_settings.title');
        }
        else if (mode === "CUSTOMER") {
          return this.$t('customer.import_settings.title');
        }
        else if (mode === "PROJECT") {
          return this.$t('project.import_settings.title');
        }
        else if (mode === "STAGE") {
          return this.$t('stage.import_settings.title');
        }
        else if (mode === "TASK_TEMPLATE") {
          return this.$t('template.import_settings.title');
        }
        else if (mode === "SKILL") {
          return this.$t('skill.import_settings.title');
        }
        else if (mode === "RESOURCE") {
          return this.$t('resource.import_settings.title');
        }
        else if (mode === "LOCATION") {
          return this.$t('location.import_settings.title');
        }
        else if (mode === "REBATE") {
          return this.$t('rebate.import_settings.title');
        }
        return this.$t('task.import_settings.title');
      }
    },
    watch: {
      source(newValue) {
        if(newValue){
          this.submitting = false;
          this.loadFile(newValue);
        }
      },
      currentSheet(newValue) {
        if (newValue) {
          this.gridApi.showLoadingOverlay();
          if (this.filename.endsWith('.pdf') ||
              this.fileType.endsWith('pdf') ||
              this.filename.endsWith('.csv')) {
            this.populatePdfContent(newValue);
          }
          else {
            this.mappedValues = {};
            this.summaryTasks = {};
            this.ownerTasks = {};
            this.directions = {};
            this.populateSheetContent(newValue);
          }
          this.gridApi.hideOverlay();
        }
      },
      show(newValue) {
        if (newValue) {
          if (this.properties) {
            if (this.headerComponentOptions) {
              this.headerComponentOptions.splice(0, this.headerComponentOptions.length, ...cloneDeep(this.properties));
            }
            else {
              this.headerComponentOptions = [...cloneDeep(this.properties)];
            }
            this.headerComponentOptions.unshift({ value: null, text: 'None' });
          }
          else {
            this.headerComponentOptions = null;
          }
        }
      }
    },
    methods: {
      onStages(stages) {
        this.stages = stages;
      },
      async loadFile(newValue) {
        await this.loadSettings();
        this.filename = newValue.name;
        this.fileType = newValue.type;
        this.currentSheet = null;
        this.workbook = null;
        this.tables = [];
        if(this.gridApi) {
          this.gridApi.showLoadingOverlay();
        }
        this.mappedValues = {};
        this.summaryTasks = {};
        this.ownerTasks = {};
        this.directions = {};
        if (this.filename.endsWith('.pdf') ||
            this.fileType.endsWith('pdf')) {
          const form = new FormData();
          if (newValue !== null) {
            form.append('file', newValue);
          }
          const self = this;
          fileService.extract(form, null).then(response => {
            const data = response.data[response.data.jobCase];
            if (typeof data !== 'undefined' && data.length > 0) {
              self.tables = [];
              for (var index = 0; index < data.length; index++) {
                // only add the table if it is not empty, check for containing
                // just quotes or new line
                if (data[index] !== '""\r\n') {
                  const table = self.convertCSVToJSON(data[index], ',');
                  var hasContent = false;
                  for (const row of Object.keys(table)) {
                    for (const col of Object.keys(table[row])) {
                      if (table[row][col] !== '') {
                        hasContent = true;
                        break;
                      }
                    }
                  }
                  
                  if (hasContent) {
                    self.tables.push(table);
                  }
                }
              }
              
              // merge all tables into a single large table for default
              self.tables.unshift([].concat.apply([], self.tables));
              self.currentSheet = self.tables[0];
            }
            else {
              if(this.gridApi) {
                this.gridApi.showNoRowsOverlay();
              }
            }
          });
        }
        else if (this.filename.endsWith('.csv')) {
          const reader = new FileReader();
          const self = this;
          reader.onload = function (e) {
            const data = new Uint8Array(e.target.result);
              
            const table = self.convertCSVToJSON(Buffer.from(data).toString('utf-8'), ',', '\n');
            self.tables.push(table);
            self.currentSheet = self.tables[0];
          };
          reader.readAsArrayBuffer(newValue);
        }
        else {
          spreadsheetRaw.loadSheet(newValue, this); // Omit third parameter as default object is used: { wBook='workbook', sOpt='sheetOptions', cSheet='currentSheet'}
        }
      },
      convertCSVToJSON(str, rowDelimiter = '\r\n') {
        const rows = str.slice(0).split(rowDelimiter);
        const start = 'A';
        var end = 'B';
        
        const ret = rows.map(row => {
          // Convert to 2D array
          const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          // Convert array to object
          var col = start;
          const obj = {};
          for (const value of values) {
            if (col > end) {
              end = col;
            }
            
            obj[col] = value.replace(/(^"|"$)/g, '').replace(/(^\r|^\n)/g, '');
            
            col = nextCol(col);
          }
          return obj;
        });
        
        this.resetColumns(start, end);
        return ret;
      },
      showSettings() {
        this.showSettingsModal = true;
      },
      treeTitle() {
        if (this.rowData !== null) {
          if (typeof this.currentColumn !== 'undefined') {
            if (typeof this.summaryTasks[this.currentColumn] !== 'undefined') {
              if (typeof this.summaryTasks[this.currentColumn].type !== 'undefined') {
                return `${this.rowData[this.headerRow - 1][this.currentColumn]} (${this.summaryTasks[this.currentColumn].type})`;
              }
              else {
                return `${this.rowData[this.headerRow - 1][this.currentColumn]}`;
              }
            }
            else if (this.headerRow !== -1) {
              return `${this.rowData[this.headerRow - 1][this.currentColumn]}`;
            }
          }
        }
        return "";
      },
      async settingsChanged(result) {
        this.settings = result;
        await this.submitUpdate();
        this.applySettingsShow = true;
      },
      applySettingsOk() {
        this.mappedValues = {};
        this.summaryTasks = {};
        this.ownerTasks = {};
        this.directions = {};
        this.detectHeaders(true);
      },
      async loadSettings() {
        const self = this;
        let changed = false;
        await importDocProfileService.list(localStorage.companyId).then((response) => {  
          const profileData = response.data[response.data.jobCase];
          if (profileData.length !== 0) {
          
            // introducing mode tag for settings
            // upgrade the existing settings
            if (profileData.length === 1 &&
                self.mode === 'TASKS' &&
                profileData[0].mode !== self.mode) {
              profileData[0].mode = self.mode;
            }
            
            const findSetting = profileData.filter(s => s.mode === self.mode);
            if (findSetting.length !== 0) {
              const settings = findSetting[0];
              
              if (self.mode === 'TASKS') {
                // add any new settings not in profile data
                for (const key in self.settings) {
                  if (typeof settings[key] === 'undefined') {
                    settings[key] = [];
                    changed = true;
                  }
                  else {
                    for (var idx = 0; idx < settings[key].length; idx++) {
                      if (settings[key][idx] === null) {
                        settings[key].splice(idx, 1);
                        changed = true;
                      }
                      else if (Array.isArray(settings[key]) &&
                               !settings[key][idx].direction) {
                        // update the settings to add the direction
                        settings[key][idx].direction = 'h';
                        changed = true;
                      }
                    }
                  }
                }
              }
              else {
                if (self.properties !== null) {
                  for (const prop of self.properties) {
                    if (typeof settings[prop.value] === 'undefined') {
                      settings[prop.value] = [{ name: prop.value, weight: 0}, { name: prop.text, weight: 0}];
                      changed = true;
                    }
                  }
                }
              }
              self.settings = settings;
              delete self.settings['kind'];
            }
            else {
              self.settings = { mode: self.mode };
              if (self.mode !== 'TASKS') {
                if (self.properties !== null) {
                  for (const prop of self.properties) {
                    if (typeof self.settings[prop.value] === 'undefined') {
                      self.settings[prop.value] = [{ name: prop.value, weight: 0}, { name: prop.text, weight: 0}];
                      changed = true;
                    }
                  }
                }
              }
            }
          }
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
        
        if (!self.settings.uuId) {
          await self.createImportDocProfile();
        }
        
        if (changed) {
          await self.submitUpdate();
        }
      },
      onTaskSelected(tasks) {
        if (tasks.length > 0) {
          this.editParentTask = tasks[0].column;
        }
        else {
          this.editParentTask = null;
        }
      },
      checkParent(key, parentTask) {
        // Check if this key is the parent
        let parentKey = this.summaryTasks[parentTask].parentTask;
        let ret = key === parentKey;
        
        // if this key is not the parent then start from the parentTask and check all keys above
        // to see if this key is a parent
        while (!ret && parentKey) {
          // check the parent of the parent
          ret = key === parentKey;
          parentKey = this.summaryTasks[parentKey].parentTask;
        }
        return ret;
      },
      selectParentOk() {
        if (this.applyToAll) {
          for (const key of Object.keys(this.summaryTasks)) {
            if (key !== this.editParentTask && !this.checkParent(key, this.editParentTask)) {
              this.summaryTasks[key].parentTask = this.editParentTask;
            }
          }
          
          // if no summary task set yet
          for (const key of Object.keys(this.directions)) {
            if (key !== this.editParentTask && this.directions[key] === 'v') {
              this.summaryTasks[key] = { keyword: this.getKeyword(key), parentTask: this.editParentTask};
              // remember the keyword used when parenting a task
              const keyword = this.summaryTasks[this.editParentTask].keyword; // the parent tasks keyword Asset Name
              const thiskey = this.getKeyword(key); // use the keyword from this column Design
              const setting = this.settings[this.mappedValues[key].value]; // the setting applied duration (Estimated Duration)
              for (const s of setting) {
                if (s.name.toLowerCase() === thiskey.toLowerCase()) {
                  s.parent = keyword;
                }
              }
            }
          }
        }
        else {
          this.summaryTasks[this.currentColumn].parentTask = this.editParentTask;
          // remember the keyword used when parenting a task
          const keyword = this.summaryTasks[this.editParentTask].keyword;
          const thiskey = this.getKeyword(this.currentColumn);
          const setting = this.settings[this.editParentField];
          for (const s of setting) {
            if (s.name.toLowerCase() === thiskey.toLowerCase()) {
              s.parent = keyword;
            }
          }
            
        }
        this.gridApi.refreshHeader();
      },
      onOwnerSelected(tasks) {
        if (tasks.length > 0) {
          this.editOwnerTask = tasks[0].column;
        }
        else {
          this.editOwnerTask = null;
        }
      },
      selectOwnerOk() {
        this.ownerTasks[this.currentColumn] = this.editOwnerTask;
        
        // remember the keyword used when linking attributes to a task
        const keyword = this.summaryTasks[this.editOwnerTask].keyword;
        const key = this.getKeyword(this.currentColumn);
        const setting = this.settings[this.editOwnerField];
        for (const s of setting) {
          if (s.name === key) {
            s.owner = keyword;
          }
        }
        
        this.gridApi.refreshHeader();
      },
      sheetHidden(name) {
        if (this.workbook.Workbook) {
          if (typeof this.workbook.Workbook.Sheets === 'undefined') {
            return false;
          }
          
          for (const sheet of this.workbook.Workbook.Sheets) {
            if (sheet.name === name) {
              return sheet.Hidden === 1;
            }
          }
        }
        return false;
      },
      sheetNames() {
        const ret = [];
        if (this.workbook) {
          if (this.tables.length !== 0) {
            for (const name of this.workbook.SheetNames) {
              if (!this.sheetHidden(name)) {
                ret.push({text: name, value: name});
              }
            }
            for (const table of this.tables) {
              ret.push({text: `${table.sheet}:${table.startCol}${table.startRow}:${table.endCol}${table.endRow}`, value: table});
            }
            return ret;
          }
          return this.workbook.SheetNames;
        }
        else if (this.filename !== null && this.filename.endsWith('.pdf')) {
          var index = 0;
          for (const table of this.tables) {
            const text = index === 0 ? `${this.$t('document.alltables')}` : `${this.$t('document.table')} ${index}`;
            ret.push({text: text, value: table});
            index++;
          }
        }
        return ret;
      },
      getMergeData(rowIndex, colIndex) {
        const offsetX = typeof this.currentSheet === 'string' || typeof this.currentSheet.startCol === 'undefined' ? 0 : this.convertLetterToNumber(this.currentSheet.startCol) - 1;
        const merges = this.workbook === null ? [] : typeof this.currentSheet === 'string' ? this.mapMerges(this.workbook.Sheets[this.currentSheet]["!merges"]) : this.mapMerges(this.workbook.Sheets[this.currentSheet.sheet]["!merges"], offsetX, this.currentSheet.startRow - 1);

        if (merges[rowIndex] &&
           merges[rowIndex][colIndex]) {
          return merges[rowIndex][colIndex];   
        }
        return null;
      },
      // ---Logic method ----//
      createColumn(col) {
        const self = this;
        return { 
           field: col,
           headerComponent: 'settingsHeaderComponent',
           headerComponentParams: { options: this.headerComponentOptions },
           tooltipField: col,
           minWidth: 180,
           flex: 1,
           lockVisible: true,
           editable: false,
           cellRenderer: 'imgOrTextCellRenderer',
           /*rowSpan: (params) => {
             const rowIndex = params.node.rowIndex;
             const colIndex = self.convertLetterToNumber(params.column.colId);
             const mergeData = self.getMergeData(rowIndex, colIndex);
             if (mergeData) {
               return mergeData.h;   
             }
             return 1;
           },
           colSpan: (params) => {
             const rowIndex = params.node.rowIndex;
             const colIndex = self.convertLetterToNumber(params.column.colId);
             const mergeData = self.getMergeData(rowIndex, colIndex);
             if (mergeData) {
               return mergeData.w;   
             }
             return 1;
           },*/
           cellStyle: function(params) {
             const colIndex = self.convertLetterToNumber(params.column.colId);
             const rowIndex = params.node.rowIndex;
             const isSubTable = typeof self.currentSheet === 'object';
             const isMerge = self.getMergeData(rowIndex, colIndex);
             const yOffset = isSubTable ? self.currentSheet.startRow : 0;
             const table = self.tables.filter(t => rowIndex + 1 <= (t.endRow - yOffset) &&
                                                   rowIndex + 1 >= (t.startRow - yOffset));
             const startColIndex = table.length !== 0 ? self.convertLetterToNumber(table[0].startCol) : 0;
             const endColIndex = table.length !== 0 ? self.convertLetterToNumber(table[0].endCol) : 0;
             const headerRow = rowIndex + 1 === self.headerRow &&
                               (startColIndex !== 0 && endColIndex !== 0 && colIndex >= startColIndex && colIndex <= endColIndex);
             const borderTop = table.length !== 0 && rowIndex + 1 === table[0].startRow &&
                               (startColIndex !== 0 && endColIndex !== 0 && colIndex >= startColIndex && colIndex <= endColIndex);
             const borderBottom = table.length !== 0 && rowIndex + 1 === table[0].endRow &&
                               (startColIndex !== 0 && endColIndex !== 0 && colIndex >= startColIndex && colIndex <= endColIndex);
             const borderLeft = table.length !== 0 && params.column.colId === table[0].startCol;
             const borderRight = table.length !== 0 && params.column.colId === table[0].endCol;
             let style = { opacity: 0.7, 'background-image': 'var(--grid-cell-disabled-image)', 'background-size': '14.00px 14.00px'};
         
            if (rowIndex + 1 >= self.startRow &&
                rowIndex + 1 <= self.endRow) {
                style = {};
            }
            
            if (rowIndex + 1 >= self.startRow &&
                rowIndex + 1 <= self.endRow && 
                (self.mappedValues[params.column.colId] !== null &&
                typeof self.mappedValues[params.column.colId] !== 'undefined')) {
                const index = self.columnDefs.findIndex(col => col.field === params.column.colId) % (self.colours.length - 1);
                style = { 'background-color': self.colours[index] };
            }
            
            if (headerRow) {
              delete style['background-image'];
              style['background-color'] = 'var(--header-row)';
              style['color'] = 'var(--header-row-text)';
            }
            
            if (isMerge) {
              //delete style['background-image'];
              //style['background-color'] = 'var(--merge-row)';
            }
            
            if (!isSubTable) {
              if (borderTop) {
                style['border-top-width'] = '1px';
                style['border-top-style'] = 'solid';
                style['border-top-color'] = 'var(--importer-table-border)';
              }
              
              if (borderBottom) {
                style['border-bottom-width'] = '1px';
                style['border-bottom-style'] = 'solid';
                style['border-bottom-color'] = 'var(--importer-table-border)';
              }
              
              if (borderLeft) {
                style['border-left-width'] = '1px';
                style['border-left-style'] = 'solid';
                style['border-left-color'] = 'var(--importer-table-border)';
              }
              
              if (borderRight) {
                style['border-right-width'] = '1px';
                style['border-right-style'] = 'solid';
                style['border-right-color'] = 'var(--importer-table-border)';
              }
            }
            
            return style;
          }
        };
      },
      resetColumns(start, end) {
        if (this.gridApi !== null) {
          this.gridApi.setGridOption('columnDefs', []);
        }
        
        if (start !== null) {
          const columns = [];
          var col = start;
          do {
            columns.push(this.createColumn(col));
            col = nextCol(col);
          }
          while (col !== end && start !== end);
          columns.push(this.createColumn(col)); // add the last column
          this.columnDefs = columns;
          if (this.gridApi !== null) {
            this.gridApi.setGridOption('columnDefs', columns);
          }
        }
      },
      populatePdfContent(data) {
        this.rowData = data;
        this.detectHeaders(true);
        this.endRow = this.rowData.length;
        if (this.headerRow !== -1) {
          for (var idx = this.headerRow; idx < this.rowData.length; idx++) {
            if (Object.keys(this.rowData[idx]).length === 0) {
              this.endRow = idx;
              break;
            }
          }
        }
      },
      checkUndefined(data) {
        if (typeof data === 'undefined') {
          data = '';
        }
        else if (typeof data !== 'string') {
          data = `${data}`;
        }
        return data;
      },
      getWorksheetValue(worksheet, key) {
        const value = worksheet[key];
        if (typeof value.f !== 'undefined') { // formula
          return value.w;
        }
        else if (value.t === 'n' && value.w) {
          return value.w;
        }
        return value.v;
      },
      /**
       * Update spreadsheet with given sheet's content
       */
      populateSheetContent(sheet) {
        if(this.sheetContent && this.sheetContent[sheet]) {
          this.rowData = this.sheetContent[sheet].dataArray;
        } else {
          const isSubtable = typeof sheet === 'object';
          const sheetName = isSubtable ? sheet.sheet : sheet;
          const worksheet = this.workbook.Sheets[sheetName];
          let start = null;
          let end = null;
          let maxRows = 0;
          if (!isSubtable) {
            this.detectTables(worksheet);
            const ref = worksheet["!ref"];
            var myRegexp = /([A-Z]+).+?:([A-Z]+)(\d+)$/g;
            var match = myRegexp.exec(ref);
            start = match !== null ? match[1] : null;
            end = match !== null ? match[2] : null;
            maxRows = match !== null ? parseInt(match[3]) : 0;
          }
          else {
            start = sheet.startCol;
            end = sheet.endCol;
            maxRows = parseInt(sheet.endRow) - parseInt(sheet.startRow);
          }
          
          const maxCol = end !== null ? this.convertLetterToNumber(end) : 0;
          if (maxCol > 600) {
            let newMax = 0;
            // do a sanity check on the column max value
            for (const key of Object.keys(worksheet)) {
              // get the column letter
              const matches = key.match(/([A-Z]+)(\d+)/);
              if (matches !== null) {
                // const row = parseInt(matches[2]);
                const col = matches[1];
                const colNum = this.convertLetterToNumber(col);
                if (colNum > newMax) {
                  newMax = colNum;
                  end = col;
                }
              }
            }
          }
          this.resetColumns(start, end);
          
          const dataArray = new Array(maxRows);
          for (var i = 0; i < dataArray.length; i++) {
            dataArray[i] = {};
          }
          
          if (!isSubtable) {
            for (const key in worksheet) {
              const data = this.checkUndefined(this.getWorksheetValue(worksheet, key));
              const matches = key.match(/([A-Z]+)(\d+)/);
              if (matches !== null) {
                const row = parseInt(matches[2]);
                const col = matches[1];
                if (!this.googleSheets ||
                    (col > 'A' && row > 1)) {
                  const exists = typeof dataArray[row - 1] !== 'undefined';
                  const obj = exists ? dataArray[row - 1] : {};
                  obj[col] = data;
                  if (dataArray.length < row) {
                    dataArray.length = row;
                  }
                  dataArray.splice(row - 1, exists ? 1 : 0, obj);
                }
              }
            }
            
            //this.images = [];
            
            // handle images
            if (this.exceljs.worksheets.length > 0) {
              for (const image of this.exceljs.worksheets[0].getImages()) {
                
                // fetch the media item with the data (it seems the imageId matches up with m.index?)
                const img = this.exceljs.model.media.find(m => m.index === image.imageId);
                const row = image.range.tl.nativeRow;
                const col = String.fromCharCode(65 + image.range.tl.nativeCol);
                const exists = typeof dataArray[row] !== 'undefined';
                const obj = exists ? dataArray[row] : {};
                var b64encoded = `data:image/${img.extension};base64,${Buffer.from(img.buffer).toString('base64')}`;
                obj[col] = b64encoded;
                if (dataArray.length < row) {
                  dataArray.length = row;
                }
                dataArray.splice(row, exists ? 1 : 0, obj);
                //this.images[`${image.range.tl.nativeRow}.${image.range.tl.nativeCol}.${img.name}.${img.extension}`] = img.buffer;
              }
            }
          }
          else {
            for (var rowIndex = sheet.startRow; rowIndex <= sheet.endRow; rowIndex++) {
              let col2 = start;
              do {
                const key2 = `${col2}${rowIndex}`;
                const data = this.checkUndefined(typeof worksheet[key2] !== 'undefined' ? this.getWorksheetValue(worksheet, key2) : '');
                const index = rowIndex - (sheet.startRow);
                const exists = typeof dataArray[index] !== 'undefined';
                const obj = exists ? dataArray[index] : {};
                obj[col2] = data;
                if (dataArray.length < index + 1) {
                  dataArray.length = index + 1;
                }
                dataArray.splice(index, exists ? 1 : 0, obj);
                col2 = nextCol(col2);
              }
              while (col2 !== end && start !== end);
              const key3 = `${col2}${rowIndex}`;
              const data = this.checkUndefined(typeof worksheet[key3] !== 'undefined' ? this.getWorksheetValue(worksheet, key3) : '');
              const index = rowIndex - (sheet.startRow);
              const exists = typeof dataArray[index] !== 'undefined';
              const obj = exists ? dataArray[index] : {};
              obj[col2] = data;
              if (dataArray.length < index + 1) {
                dataArray.length = index + 1;
              }
              dataArray.splice(index, exists ? 1 : 0, obj);
            }
          }
          
          this.rowData = dataArray;  
          this.detectTable = 0;    
          this.autoSelect(true, false);
        }
      },
      selectNone() {
        this.mappedValues = {};
        this.summaryTasks = {};
        this.ownerTasks = {};
        this.directions = {};

        this.gridApi.refreshHeader();
        this.gridApi.redrawRows([]);
      },
      async autoSelect(redraw, loadSettings) {   
        if (loadSettings) {
          await this.loadSettings();
        }   
        const self = this;
        this.startRow = 1;
        this.mappedValues = {};
        const isSubTable = typeof this.currentSheet === 'object';
        const hasTables = self.tables.length !== 0;
        this.detectHeaders(redraw, isSubTable ? 0 : hasTables ? self.tables[self.detectTable].startRow - 1 : 0, isSubTable ? -1 : hasTables ? self.tables[self.detectTable].endRow - 1 : -1);
        this.detectTable++;
        if (this.detectTable >= this.tables.length) {
          this.detectTable = 0;
        }
        
        this.endRow = this.rowData.length;
        if (this.endRow > 0) {
          for (var idx = this.headerRow; idx < this.rowData.length; idx++) {
            if (this.rowData[idx] && Object.keys(this.rowData[idx]).length === 0) {
              this.endRow = idx;
              break;
            }
          }
        }
        this.gridApi.clearRangeSelection();
        this.gridApi.redrawRows([]);
        this.gridApi.refreshHeader();
      },
      mapKeywords(keyword, column, exclude, override) {
        if (this.settings !== null) {
          for (const entry in this.settings) {
            if (entry !== 'uuId') {
              for (const term of this.settings[entry]) {
                if (entry !== 'mode') {
                  if (keyword !== null &&
                      typeof keyword === 'string' &&
                      term !== null && typeof term !== 'undefined' &&
                      keyword &&
                      (keyword.toLowerCase() === term.name.toLowerCase() ||
                      // search for a match without spaces e.g. First Name as firstname
                      keyword.replace(/\s+/g, '').toLowerCase() === term.name.toLowerCase()) &&
                      
                      // if this column has not been mapped yet then summary_name, desc, notes or tag can be applied as
                      // these can be applied to multiple columns
                      ((this.mappedValues[column] === null && (entry === 'summary_name' ||
                       entry.endsWith('desc') ||
                       entry.endsWith('notes') ||
                       entry === 'tag')) ||
                       // check if this entry has already been mapped and if it has check the weight
                       (!this.checkMappedKey(entry, column) ||
                      (this.getMappedValueWeight(column) < term.weight) && override))) { // summary_name, notes, tag and desc can be repeated but other columns not
                    term.weight++; // increment the weight to indicate this choice is correct
                    this.setMappedValue(column, entry, term.name, term.weight);
                    this.directions[column] = term.direction;
                    delete this.summaryTasks[column];
                    this.addTasks(entry, column, term.name);
                    
                    if (term.owner) {
                      const ownerTask = this.findOwnerTask(term.owner);
                      if (ownerTask) {
                        this.ownerTasks[column] = ownerTask;
                      }
                    }
                    
                    if (term.parent) {
                      const parentTask = this.findOwnerTask(term.parent);
                      if (parentTask) {
                        this.summaryTasks[column].parentTask = parentTask;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        else {
          for (const term in this.map) {
            if (keyword && keyword.toLowerCase().includes(term)) {
              const column = `$${column}`;
              this.setMappedValue(column, term);
            }
          }
        }
        
        // update preview flag
        if (!this.canPreview) {
          for (const key of Object.keys(this.directions)) {
            if (this.directions[key] === 'v') {
              this.canPreview = true;
              break;
            }
          }
        }
      },
      findOwnerTask(term) {
        for (const key of Object.keys(this.summaryTasks)) {
          const task = this.summaryTasks[key];
          if (task.keyword.toLowerCase() === term.toLowerCase()) {
            return key;
          }
        }
        return null;
      },
      mapMerges(merges, offsetX = 0, offsetY = 0) {
        const ret = {};
        if (!merges) {
          return ret;
        }
        
        for (const merge of merges) {
          // s, e, c, r
          const start = merge.s;
          const end = merge.e;
          if (!ret[start.r - offsetY]) {
            ret[start.r - offsetY] = {};
          }
          // Numbers are zero based so to match our columns we need  + 1
          ret[start.r - offsetY][(start.c - offsetX) + 1] = { w: ((end.c - offsetX) - (start.c - offsetX)) + 1, h: ((end.r - offsetY) - (start.r - offsetY)) + 1 };
        }
        return ret;
      },
      convertLetterToNumber(val) {
        var base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', result = 0;
        
        for (let i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
          result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
        }
        
        return result;
      },
      convertNumberToLetters(num) {
        let letters = ''
        while (num >= 0) {
          letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
          num = Math.floor(num / 26) - 1
        }
        return letters
      },
      isPartOfMerge(col, merges, i) {
        for (let row = i; row >= 0; row--) {
          const merge = merges[row];
          if (merge && merge[col] &&
              i <= merge[col].h + row) {
            return merge[col].w;
          }
        }
        return 0;
      },
      detectHeaders(redraw, startingRow = 0, endingRow = -1) {
        // look for continuous text data
        this.headerRow = -1;
        var max = 0;
        const end = endingRow === -1 ? this.rowData.length : endingRow;
        var rowIndex = startingRow;
        const offsetX = typeof this.currentSheet === 'string' || typeof this.currentSheet.startCol === 'undefined' ? 0 : this.convertLetterToNumber(this.currentSheet.startCol) - 1;
        const merges = this.workbook === null ? [] : typeof this.currentSheet === 'string' ? this.mapMerges(this.workbook.Sheets[this.currentSheet]["!merges"]) : this.mapMerges(this.workbook.Sheets[this.currentSheet.sheet]["!merges"], offsetX, this.currentSheet.startRow - 1);
        for (var i = startingRow; i < end; i++) {
          const row = this.rowData[i];
          const rowMerges = merges.lenght > i ? merges[i] : {};
          if (typeof row !== 'undefined') {
            var count = 0;
            rowIndex++;
            for (const def of this.columnDefs) {
              if (typeof row[def.field] !== 'undefined' &&
                  row[def.field] !== '') {
                count++;
                // check merged cell data
                if (rowMerges) {
                  const col = this.convertLetterToNumber(def.field);
                  const merge = rowMerges[col];
                  if (merge) {
                    count += merge.w - 1; // Add extra merged cells
                    /*for (let n = 1; n < merge.h + 1; n++) {
                      if (this.rowData[i+n][def.field] !== 'undefined' &&
                         this.rowData[i+n][def.field] !== '') {
                        count++;
                        break;
                      }
                    }*/
                  }     
                }
              }
              else {
                const col = this.convertLetterToNumber(def.field) - offsetX;
                // check if this cell is within a merged cell
                count += this.isPartOfMerge(col, merges, i);
              }
            }

            if (count > max) {
              max = count;
              this.headerRow = rowIndex;
              this.startRow = rowIndex + 1; // startRow is based from 1 plus the header row index
            }
          }
        }
        
        if (this.headerRow !== -1) {
          // scan headers looking for keywords
          this.scanForKeywords(this.headerRow - 1, merges, true, offsetX);
          this.scanForKeywords(this.headerRow, merges, false, offsetX);
        }
        
        if (redraw) {
          this.gridApi.redrawRows([]);
          this.gridApi.refreshHeader();
        }
      },
      scanForKeywords(row, merges, override, offsetX) {
        const rowData = this.rowData[row];
        if (typeof rowData === 'undefined') {
          return;  // end of document
        }
        for (const def of this.columnDefs) {
          const key = def.field;
          const data = rowData[key] && rowData[key] !== '' ?
            rowData[key] : this.getMergedRowData(key, row, merges, offsetX);
          this.mapKeywords(data, key, '', override);
        }
      },
      getMergedRowData(key, row, merges, offsetX) {
        const col = this.convertLetterToNumber(key) - offsetX;
        // check if this cell is within a merged cell
        for (let i = row; i >= 0; i--) {
          const merge = merges[i];
          if (merge && merge[col] &&
              row <= merge[col].h + i) {
            return this.rowData[i][key];
          }
        }
        return null;
      },
      detectTables(worksheet) {
        this.tables = [];
        var startRow = -1;
        var startCol = -1;
        var previousRow = -1;
        var previousCol = -1;
            const offsetX = typeof this.currentSheet === 'string' || typeof this.currentSheet.startCol === 'undefined' ? 0 : this.convertLetterToNumber(this.currentSheet.startCol) - 1;
        const merges = this.workbook === null ? [] : typeof this.currentSheet === 'string' ? this.mapMerges(this.workbook.Sheets[this.currentSheet]["!merges"]) : this.mapMerges(this.workbook.Sheets[this.currentSheet.sheet]["!merges"], offsetX, this.currentSheet.startRow - 1);
        for (const key in worksheet) {
          const matches = key.match(/([A-Z]+)(\d+)/);
          if (matches !== null) {
            const row = parseInt(matches[2]);
            const col = matches[1];
            if (row > previousRow + 1 &&
                startRow !== -1) {
            
              // a new table, close off the current one
              if (startRow !== previousRow) { // tables have more than one row
                this.tables.push({sheet: this.currentSheet, startRow: startRow, startCol: startCol, endRow: previousRow, endCol: previousCol});
              }
              previousCol = -1;
              startRow = -1;
              startCol = -1;
            }
            
            if (startRow === -1) {
              startRow = row;
            }
            
            if (startCol === -1) {
              startCol = col;
            }
            else if (this.convertLetterToNumber(col) < this.convertLetterToNumber(startCol)) {
              startCol = col;
            }
            
            if (row > previousRow) {
              previousRow = row;
            }
            
            if (this.convertLetterToNumber(col) > this.convertLetterToNumber(previousCol)) {
              previousCol = col;
            }
            
            // is this cell merged
            const merge = merges[row - 1];
            const colIdx = this.convertLetterToNumber(col);
            if (merge && merge[colIdx]) {
              // move to the end of the merge
              previousRow = row + merge[colIdx].h;
              previousCol = this.convertNumberToLetters(colIdx + merge[colIdx].w);
            }
          }
        }
        if (startRow !== -1 && startRow !== previousRow) { // make sure the table is larger than 1 row
          this.tables.push({sheet: this.currentSheet, startRow: startRow, startCol: startCol, endRow: previousRow, endCol: previousCol});
        }
      },
      addTasks(field, column, keyword) {
      
        if (field === 'summary_name' || field === 'milestone_name' || field === 'name') {
          this.summaryTasks[column] = { type: field === 'summary_name' ? 'Summary Task' : field === 'milestone_name' ? 'Milestone' : 'Task', keyword: keyword };
        }
        else if (this.directions[column] === 'v') {
          
            this.summaryTasks[column] = { type: 'Task', keyword: keyword };
        }
        
        let hasVert = false;
        for (const key of Object.keys(this.directions)) {
          if (this.directions[key] === 'v') {
            hasVert = true;
            break;
          }
        }
        this.canPreview = this.properties || Object.keys(this.summaryTasks).length > 0 || hasVert;
      },
      canSelectParent(column) {
        var count = 0;
        for (const key in this.summaryTasks) {
          if (key !== column && this.summaryTasks[key].type === 'Summary Task') {
            count++;
          }
        }
        return count > 0 && this.show;
      },
      canSelectOwner(column, value) {
        if (this.show && value) {
          for (const key in this.summaryTasks) {
            if (key !== column) {
              if (value.startsWith('summary_') &&
                  this.summaryTasks[key].type === 'Summary Task') {
                return true;
              }
              else if (value.startsWith('milestone_') &&
                  this.summaryTasks[key].type === 'Milestone') {
                return true;
              }
              else if (!value.startsWith('milestone_') &&
                       !value.startsWith('summary_') &&
                       this.summaryTasks[key].type === 'Task') {
                return true;
              }
            }
          }
        }
        return false;
      },
      hasParent(task, column) {
        var parent = task.parentTask;
        var result = false;
        
        while (parent) {
          if (parent === column) {
            result = true;
            break;
          }
          parent = this.summaryTasks[parent].parentTask;
        }
        
        return result;
      },
      addParents(key, checkbox) {
        const parentTask = this.summaryTasks[key].parentTask;
        this.taskData.push({ id: key, column: key, parent: parentTask, name: this.rowData[this.headerRow-1][key].replace(/[\n\r]/g, ''), type: this.summaryTasks[key].type, checkbox: checkbox });
        if (parentTask && this.taskData.filter(t => t.id === parentTask).length === 0) {
          this.addParents(parentTask);
        }
      },
      prepareTasks(prefix) {
        this.taskData = [];
        for (const key in this.summaryTasks) {
          if (key !== this.currentColumn && 
              ((prefix === 'summary_' && this.summaryTasks[key].type === 'Summary Task') ||
               (prefix === 'milestone_' && this.summaryTasks[key].type === 'Milestone') ||
               (prefix === '' && this.summaryTasks[key].type === 'Task')) &&
              !(this.hasParent(this.summaryTasks[key], this.currentColumn)) && 
              typeof this.rowData[this.headerRow-1][key] !== 'undefined' &&
              this.taskData.filter(t => t.id === key).length === 0) {
            const parentTask = this.summaryTasks[key].parentTask;
            this.taskData.push({ id: key, column: key, parent: parentTask, name: this.rowData[this.headerRow-1][key].replace(/[\n\r]/g, ''), type: this.summaryTasks[key].type });
            if (parentTask && prefix !== 'summary_' &&
                this.taskData.filter(t => t.id === parentTask).length === 0) {
              this.addParents(parentTask, prefix === 'summary_');
            }
          }
        }
      },
      getParent(column) {
        return column in this.summaryTasks ? this.summaryTasks[column].parentTask : null;
      },
      getOwner(column) {
        return this.ownerTasks[column];
      },
      updateParent(column, field) {
        const keyword = this.rowData[this.headerRow - 1][column];
      
        if (field === 'summary_name' || 
            field === 'milestone_name' || 
            field === 'name' ||
            this.getDirection(column) === 'v') {
          if (typeof this.summaryTasks[column] === 'undefined') {
            this.summaryTasks[column] = { keyword: keyword };
            this.canPreview = Object.keys(this.summaryTasks).length > 0;
          }
          
          if (this.canSelectParent(column)) {
            this.editParentTask = typeof this.summaryTasks[column].parentTask !== 'undefined' ? this.summaryTasks[column].parentTask : null;
            this.currentColumn = column;
            this.editParentField = field;
            this.prepareTasks('summary_');
            this.applyToAll = false;
            this.selectParentShow = true;
          }
        }
      },
      updateOwner(column, field) {
        // const keyword = this.rowData[this.headerRow - 1][column];
      
        if (field !== 'summary_name' && field !== 'milestone_name' && field !== 'name') {
          this.editOwnerField = field;
          this.editOwnerTask = typeof this.ownerTasks[column] !== 'undefined' && 
            typeof this.ownerTasks[column] !== 'undefined' 
              ? this.ownerTasks[column] 
              : null;
          this.currentColumn = column;
          let prefix = '';
          if (field.startsWith('summary_')) {
            prefix = 'summary_';
          }
          else if (field.startsWith('milestone_')) {
            prefix = 'milestone_';
          }
          this.prepareTasks(prefix);
          this.selectOwnerShow = true;
        }
      },
      getDirection(column) {
        return typeof this.directions[column] !== 'undefined' 
              ? this.directions[column] 
              : 'h';
      },
      toggleDirection(column) {
        this.directions[column] = this.getDirection(column) === 'h'
          ? 'v'
          : 'h';
          
        if (!this.canPreview && this.directions[column] === 'v') {
          this.canPreview = true;
        }
        else {
          this.canPreview = Object.keys(this.summaryTasks).length > 0;
        }
        this.gridApi.refreshHeader();
      },
      removeCurrentSetting(column, field, keyword, direction) {
        var updated = false;
        const list = this.settings[field];
        if (typeof list !== 'undefined') {
          for (const term of list) {
            if (typeof keyword !== 'undefined' &&
                term !== null && typeof term !== 'undefined' &&
                keyword.toLowerCase() === term.name.toLowerCase() &&
                (!term.direction || term.direction === direction)) { // handling for no direction or direction being set
              const index = list.findIndex(function(val) {
                return val.name === term.name && (val.direction === direction || (!val.direction && direction === 'h'));
              });
              if (index !== -1) {
                if (list[index].weight !== 0) {
                  this.$set(list[index], 'weight', list[index].weight - 1);
                  updated = true;
                }
                
                if (list[index].weight === 0) {
                  // remove it from the list
                  list.splice(index, 1);
                  updated = true;
                }
              }
            }
          }
        }
        return updated;
      },
      selectSingle(value) {
        return value && value !== 'summary_name' &&
               value !== 'summary_identifier' &&
               !value.endsWith('notes') &&
               !value.endsWith('desc') &&
               !value === 'tag'; 
      },
      getMappedKey(value) {
        for (const key in this.mappedValues) {
          if (value && this.mappedValues[key] !== null &&
              this.getDirection(key) !== 'v' &&
              this.mappedValues[key].value === value) {
            return key;
          }
        }
        return null;
      },
      setMappedValue(column, value, term, weight) {
        const direction = this.getDirection(column);
        if (this.selectSingle(value) &&
            direction !== 'v') {
          const key = this.getMappedKey(value);
          if (key !== null) {
            // update the ML
            this.removeCurrentSetting(key, value, this.getKeyword(key), this.getDirection(key));
            this.mappedValues[key] = null;
          }
        
        }
        
        // reset the owner task
        delete this.ownerTasks[column];
        
        if (value !== null) {
          this.mappedValues[column] = { value: value, term: term, weight: weight, direction: direction };
        }
        else {
          // remove any instances where this column is a parent
          for (const obj of Object.keys(this.summaryTasks)) {
            if (this.summaryTasks[obj].parentTask === column) {
              this.summaryTasks[obj].parentTask = null;
            }
          }
          this.mappedValues[column] = value;
        }
      },
      getMappedValueWeight(column) {
        if (this.mappedValues[column] !== null &&
            typeof this.mappedValues[column] !== 'undefined') {
          return this.mappedValues[column].weight;
        }
        return -1;
      },
      checkMappedKey(entry, column) {
        if (this.mappedValues[column] !== null &&
            typeof this.mappedValues[column] !== 'undefined') {
          return this.mappedValues[column].term;
        }
        else {
          return this.getMappedKey(entry);
        }
      },
      getKeyword(column) {
        for (let idx = this.headerRow - 1; idx < this.rowData.length; idx++) {
          const keyword = idx >= 0 && idx < this.rowData.length ? this.rowData[idx][column] : '';
          if (typeof keyword !== 'undefined' &&
              keyword !== '') {
            return keyword;
          }
          else {
            const offsetX = typeof this.currentSheet === 'string' || typeof this.currentSheet.startCol === 'undefined' ? 0 : this.convertLetterToNumber(this.currentSheet.startCol) - 1;
            const merges = this.workbook === null ? [] : typeof this.currentSheet === 'string' ? this.mapMerges(this.workbook.Sheets[this.currentSheet]["!merges"]) : this.mapMerges(this.workbook.Sheets[this.currentSheet.sheet]["!merges"], offsetX, this.currentSheet.startRow - 1);
            const data = this.getMergedRowData(column, idx, merges, offsetX);
            if (data) {
              return data;
            }
          }
        }
        return null;
      },
      updateSettings(column, field, prevValue, direction, prevDirection) {
        const keyword = this.getKeyword(column);
        if (keyword === null) {
          return;
        }
        this.removeCurrentSetting(column, prevValue, keyword, prevDirection);
        if (prevValue === 'summary_name' || prevValue === 'milestone_name' || prevValue === 'name') {
          delete this.summaryTasks[column];
          this.canPreview = Object.keys(this.summaryTasks).length > 0;
        }
        
        if (field !== null && keyword !== '') {
          const list = this.settings[field];
          if (typeof list !== 'undefined' ) {
            const exists = list.filter(k => keyword === k.name && (k.direction === direction || !k.direction));
            if (exists.length === 0) {
              list.push({ name: keyword, weight: 1, direction: direction });
            }
            else {
              exists[0].weight++;
              if (!exists[0].direction) {
                exists[0].direction = direction; // update the direction
              }
            }
          }
          else {
            this.settings[field] = [{ name: keyword, weight: 0, direction: direction }];
          }
          
          this.addTasks(field, column, keyword);
        }
        
        this.gridApi.refreshHeader();
          
      },
      async createImportDocProfile() {
        await importDocProfileService.create([this.settings],
                          localStorage.companyId).then((response) => {  
          const data = response.data[response.data.jobCase];
          this.settings.uuId = data[0].uuId;
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
      },
      async submitUpdate() {
        await importDocProfileService.update([this.settings],
                          localStorage.companyId).then((/*response*/) => {  
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
      },
      // ---Event handler -----//
      errorDialogOk() {
        this.state.errorDialogShow = false;
      },
      modalCancel() {
        this.$emit("modal-cancel")
      },
      parsePath(value) {
        if (value.indexOf('\r\n') !== -1) {
          return value.replace(/\r\n/g, '/')
        }
        return value.replace(/\n/g, '/');
      },
      async processData(idx, key, data, list, map, project, field, value) {
        if (field === 'task_path') {
          map[this.parsePath(value)] = data.id; // set the id in the map of this path so that we can recall the id
          // read the exported path to the task by removing the name of this task from the end of the path
          // so that we can get the id of the parent
          const path = value.indexOf('\r\n') !== -1 ? value.split('\r\n') : value.indexOf('\n') !== -1 ? value.split('\n') : value.split('/');
          path.splice(path.length - 1, 1);
          if (path.length !== 0) {
            const taskpath = path.join('/');
            data.parent = map[taskpath];
            
            // in a broken path we may have multiple matches, check the idx is less than this one
            if (data.parent && map[data.parent] && map[data.parent].idx > idx) {
              delete data.parent;
            }
            
            // if this row is a summary task then data won't be used, try to find the summary task
            if (!('name' in data) &&
                list.length > 1 &&
                typeof list[idx - 1] !== 'undefined') {
              list[idx - 1].parent = data.parent;
            }
          }
          
        }
        else if (typeof data[field] === 'undefined' ||
                 field === 'type') {
          if (field === 'desc' || field === 'notes' || field === 'tag') {
            data[field] = value;
            data['firstKeyword' + field] = this.getKeyword(key); // save the keyword to use if we get more desc/notes
          }
          else {
            if (field === 'type' &&
                this.mode === 'TASKS' &&
                (value !== 'Summary Task' &&
                value !== 'Task' &&
                value !== 'Milestone')) {
              // not a valid type
            }
            else {
              data[field] = typeof value === 'string' ? value.trim() : value;
            }
          }
        }
        else if (field === 'desc' || field === 'notes' || field === 'tag') {
          if (data['firstKeyword' + field]) {
            data[field] = `${data['firstKeyword' + field]}: ${data[field]}\r\n${this.getKeyword(key)}: ${value}`;
            delete data['firstKeyword' + field];
          }
          else {
            data[field] = `${data[field]}\r\n${this.getKeyword(key)}: ${value}`;
          }
        }
        else if (field === 'currency') {
          data[field] = value;
        }
        
        if (field === 'name') {
          data['id'] = `${key}${idx}`;
          if (typeof data[field] === 'string') {
            data[field] = data[field].replace(/[\n\r]/g, '');
          }
          
          // set the type to task
          if (this.mode === 'TASKS') {
            data['type'] = "Task";
            if (!data['currency'] && project) {
              data['currency'] = project.currencyCode;
            }
          }
          
          map[key] = data;
          var parentId = this.getParentId(key, idx, map);
          if (parentId !== null) {
            data['parent'] = parentId;
            const parentPath = map[parentId].path;
            data['path'] = `${parentPath}/${data.name}`;
          }
          else {
            data['path'] = data.name;
          }
        }
        else if (field === 'fixedcost') {
          if (data[field].startsWith('$')) {
            data[field] = data[field].substr(1);
          }
        }
        else if (field === 'staffs' && typeof data[field] === 'string') {
          data[field] = await this.getStaffUuids(data[field].split(', '));
        }
        else if (field === 'project') {
          data[field] = await this.getProjectUuids(data[field]);
        }
        else if (field === 'resources' && typeof data[field] === 'string') {
          data[field] = await this.getResourceUuids(data[field].split(', '));
        }
        else if (field === 'skills' && typeof data[field] === 'string') {
          data[field] = await this.getSkillUuids(data[field].split(', '));
        }
        else if (field === 'company' && typeof data[field] === 'string') {
          const companies = data[field].split(',');
          data[field] = null;
          for (const company of companies) {
            data[field] = await this.getCompanyUuid(company);
          }
        }
        else if (field === 'location') {
          data[field] = await this.getLocationUuid(data[field]);
        }
        else if (field === 'customer') {
          data[field] = await this.getCustomerUuid(data[field]);
        }
        else if (field === 'department' && typeof data[field] === 'string') {
          let company = null;
          let companyUuid = null;
          if (data['company'] && typeof data['company'] === 'string') {
            company = data['company'];
          }
          else if (data['company'] && typeof data['company'] === 'object') {
            company = data['company'].name;
            companyUuid = data['company'].uuId;
          }
          data[field] = await this.getDepartmentUuids(data[field].split(', '), company, companyUuid);
        }
        else if (field === 'stages' && typeof data[field] === 'string') {
          data[field] = await this.getStageUuids(data[field].split(', '));
        }
        else if (field === 'status' && typeof data[field] === 'string' &&
                 this.mode === 'PROJECT') {
          const status = await this.getStageUuids(data[field].split(', '));
          if (status.length > 0) {
            data[field] = status[0];
          }
        }
        else if (field === 'rebates' && typeof data[field] === 'string') {
          // Every task must adopt the parent project's rebate list as a start
          // Any additional rebates from that task are then added
          const pRebates = project !== null ? project.rebateList.map(pr => pr.uuId) : [];
          var tRebates = await this.getRebateUuids(data[field].split(', '));
          const extraRebates = tRebates.filter(r => !pRebates.includes(r.uuId));
          data[field] = project !== null && extraRebates.length === 0 ? project.rebateList : extraRebates;
        }
        else if (field === 'constraint' ||
                 field === 'constraintTime') {
          if (typeof data['constraint'] !== 'object') {
            data['constraint'] = { type: data['constraint'], time: data['constraintTime']};
          }
          else if (field === 'constraintTime') {
            data['constraint'].time = data['constraintTime'];           
          }        
        }
        return { data: data, list: list, map: map };
      },
      taskExists(name, key, idx, map) {
        // we are checking for contiguous rows with the same name
        // SHOT1
        // SHOT1
        // SHOT1
        // if we find non-contiguous names the sequence is broken
        // SHOT2 << broken, we can re-use the task name for a different task
        // SHOT1
        // SHOT1
        for (let i = idx -1; i !== 0; i--) {
          const prev = `${key}${i}`;
          if (map[prev]) {
            if (map[prev].name === name) {
              return true;
            }
            else if (map[prev].name &&
                     map[prev].name !== name) {
              return false;       
            }
          }
        }
        return false;
      },
      async linkData(idx, key, data, list, map, project, field, value) {
        const owner = this.ownerTasks[key];
        if (owner) {
          const tasks = map[owner];
          if (tasks) {
            if (Array.isArray(tasks)) {
              for (let i = tasks.length - 1; i >= 0; i--) {
                const task = tasks[i];
                if (task.index <= idx) {
                  const result = await this.processData(idx, key, task, list, map, project, field, value);
                  list = result.list;
                  map = result.map;
                  tasks[i] = result.data;
                  break;
                }
              }
            }
            else {
              const result = await this.processData(idx, key, tasks, list, map, project, field, value);
              list = result.list;
              map = result.map;
              map[owner] = result.data;
            }
          }
          else {
            // try to find a column that is a task 
            for (let j = 0; j < list.length; j++) {
              const task = list[j];
              if (task.column === owner && task.index === idx) {
                const result = await this.processData(idx, key, task, list, map, project, field, value);
                list = result.list;
                map = result.map;
                list[j] = result.data;
                break;
              }
            }
          }
        }
        else if (data !== null) {
          const result = await this.processData(idx, key, data, list, map, project, field, value);
          data = result.data;
          list = result.list;
          map = result.map;
        }
        return { data: data, list: list, map: map };
      },
      transformFieldName(field) {
        if (field.startsWith('summary_')) {
          return field.substr(8);
        }
        else if (field.startsWith('milestone_')) {
          return field.substr(10);
        }
        else {
          return field;
        }
      },
      isDuration(val) {
        if (!isNaN(val)) {
          return true;
        }// eslint-disable-next-line
        else if (val && /^[\d,\.,D,m,h,W,M,Y]+$/.test(val)) {
          return true;
        }
        return false;
      },
      async processRow(idx, row, list, fields, map, project, allfields) {
        // process each row
        let data = { id: idx };
        var current_summary = {};
        var current_milestone = {};
        const keys = Object.keys(this.mappedValues).sort();
        for (const key of keys) {
          const field = this.mappedValues[key] !== null ? this.mappedValues[key].value : null;
          if (typeof row[key] !== 'undefined') {
            if (field !== null && row[key] !== '') {
              const direction = this.getDirection(key);
              const realFieldName = this.transformFieldName(field);
              if (!fields.includes(realFieldName)) { 
                fields.push(realFieldName);  // keep track of fields the user has selected
              }
              
              if (direction === 'v') {
                // special case for v columns, don't create on 0
                if (row[key] !== '0' &&
                    !(field === 'duration' && !this.isDuration(row[key]))) {
                  const keyword = this.getKeyword(key);
                  // Add a task with the header name
                  let task = { 
                    id: `${key}${idx}`,
                    column: key,
                    index: idx, 
                    type: field.startsWith('summary_') ? "Summary Task" : field.startsWith('milestone_') ? 'Milestone' : 'Task', 
                    text: row[key], 
                    name: keyword.replace(/[\n\r]/g, '')
                  };
                        
                  var parentId = this.getParentId(key, idx, map);
                  if (parentId !== null) {
                    task['parent'] = parentId;
                    const parentPath = map[parentId].path;
                    task['path'] = `${parentPath}/${task.name}`;
                  }
                  else {
                    data['path'] = data.name;
                  }
                  
                  // add the cell contents
                  const result = await this.processData(idx, key, task, list, map, project, this.transformFieldName(field), row[key]);
                  task = result.data;
                  map[task.id] = task;
                  list.push(task);
                }
              }
              else if (field === 'summary_name' || field === 'milestone_name') {
                //const existingTasks = map[key];
                
                // only add one summary or milestone task if a row contains the same name repeatedly
                // example: Explosion | Concept
                //          Explosion | Layout
                //          Explosion | Render
                const newSummaryTask = (field === 'summary_name' && (current_summary.name !== row[key].replace(/[\n\r]/g)));
                const newMilestone = (field == 'milestone_name' && (current_milestone.name !== row[key].replace(/[\n\r]/g)));
                if (newSummaryTask ||
                    newMilestone) {
                  const copyData = (typeof current_summary.name === 'undefined' && newSummaryTask) || 
                                    (typeof current_milestone.name === 'undefined' && newMilestone);
                  const summary_data = `${key}${idx}` in map ? map[`${key}${idx}`] : { id: `${key}${idx}`, column: key, index: idx, type: field === 'summary_name' ? "Summary Task" : 'Milestone', text: row[key], name: row[key].replace(/[\n\r]/g, '')};
                  if (typeof this.summaryTasks[key] !== 'undefined') {
                    this.summaryTasks[key].id = `${key}${idx}`;
                  }
                  
                  var summaryParentId = this.getParentId(key, idx, map);
                  if (summaryParentId !== null) {
                    summary_data['parent'] = summaryParentId;
                    const parentPath = map[summaryParentId].path;
                    summary_data['path'] = `${parentPath}/${summary_data.name}`;
                  }
                  else {
                    summary_data['path'] = summary_data.name;
                  }
                  // set the id of data in case we are importing exported tasks
                  if (fields.includes('task_path')) {
                    data.id = summary_data.id;  // this will be changed if a name for a task is found
                  }
                  const exists = this.taskExists(summary_data.name, key, idx, map);
                  // don't add to the map if the previous row has the same name
                  if (!exists) {
                    list.push(summary_data);
                    if (!(summary_data.id in map)) { // don't add it to the map twice
                      map[summary_data.id] = summary_data;
                      map[summary_data.path] = summary_data.id;
                      if (typeof map[key] === 'undefined') {
                        map[key] = [summary_data];
                      }
                      else {
                        map[key].push(summary_data);
                      }
                    }
                  }
                                    
                  if (field === 'summary_name') {
                    if (copyData) {
                      for (const valkey of Object.keys(current_summary)) {
                        summary_data[valkey] = current_summary[valkey];
                      }
                    }
                    current_summary = summary_data;
                  }
                  else {
                    if (copyData) {
                      for (const valkey of Object.keys(current_milestone)) {
                        summary_data[valkey] = current_milestone[valkey];
                      }
                    }
                    current_milestone = summary_data;
                  }
                }
              }
              else if (field.startsWith('summary_') && allfields) {
                const result = await this.linkData(idx, key, current_summary, list, map, project, field.substr(8), row[key]);
                current_summary = result.data;
                list = result.list;
                map = result.map;
              }
              else if (field.startsWith('milestone_') && allfields) {
                const result = await this.linkData(idx, key, current_milestone, list, map, project, field.substr(10), row[key]);
                current_milestone = result.data;
                list = result.list;
                map = result.map;
                
              }
              else if (allfields || field === 'name') {
                const result = await this.linkData(idx, key, data, list, map, project, field, row[key]);
                data = result.data;
                list = result.list;
                map = result.map;
              }
            }
          }
        }
        if (typeof data.name !== 'undefined' ||
            this.mode === 'PREDECESSOR') {
          data.idx = idx;
          list.push(data);
          map[data.id] = data;
          map[data.path] = data.id;
        }
        return { list: list, fields: fields, map: map }
      },
      async preparePreview() {
        this.preparing = true;
        this.staffs = {};
        this.skills = {};
        this.rebates = {};
        this.stages = [];
        this.resources = {};
        this.companies = {};
        this.locations = {};
        this.customers = {};
        this.departments = {};
        
        const project = this.projectId !== null ? await projectService.get([{ uuId: this.projectId }], ['REBATE'])
        .then(response => {
          const list = response.data[response.data.jobCase];
          return list && list.length > 0? list[0] : null;
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          return null;
        }) : null;
        
        let list = [];
        let fields = [];
        let map = {};
        // first process the rows and build the map
        for (var idx = this.startRow - 1; idx < this.endRow; idx++) {
          const row = this.rowData[idx];
          if (typeof row !== 'undefined') {
            const result = await this.processRow(idx, row, list, fields, map, project, false);
            list = result.list;
            fields = result.fields;
            map = result.map;
          }
        }
        list = [];
        // then process the rows and fill the list
        for (var idx2 = this.startRow - 1; idx2 < this.endRow; idx2++) {
          const row = this.rowData[idx2];
          if (typeof row !== 'undefined') {
            const result = await this.processRow(idx2, row, list, fields, map, project, true);
            list = result.list;
            fields = result.fields;
            map = result.map;
          }
        }
        
        if (project !== null) {
          // Any items in the list that don't have a rebate get the project's rebates
          list.forEach(l => {
            if (typeof l.rebates == 'undefined') {
              l.rebates = project.rebateList;
            }
          })
        }
        
        await getCustomFieldInfo(this, this.getEntity());
        
        if (this.mode === 'TASKS') {
          if (this.projectId === null) {
            this.requiredFields = ['project'];
          }
          else {
            this.requiredFields = [];
          }
          
          if (fields.includes('fixedcost')) {
            this.requiredFields.push('currency');
          }
        }
        else if (this.mode === 'BOOKING') {
          this.requiredFields = ['name', 'project', this.properties.find(v => v.value === 'staffs') ? 'staffs' : 'resources'];
        }
        else if (this.mode === 'ACTIVITY') {
          this.requiredFields = ['name', this.properties.find(v => v.value === 'staffs') ? 'staffs' : 'resources'];
        }
        else if (this.mode === 'NONWORK') {
          this.requiredFields = ['name', 'staffs'];
        }
        else if (this.mode === 'STAFF') {
          this.requiredFields = ['name', 'email', 'payfrequency', 'payamount', 'type', 'location', 'company'];
          const inUse = await getStaffEmailInUse(list);
          for (const staff of inUse) {
            if (staff.email.length > 0) {
              const found = list.filter(l => l.email && l.email.toLowerCase() === staff.email.toLowerCase());
              if (found.length !== 0) {
                found[0].existingUuId = staff.uuId;
                found[0].emailErrorMessage = 'staff.error.email_is_used';
              }
            }
          }
          
          // check for duplicates in the list
          for (const staff of list) {
            if (staff.email) {
              const instances = list.filter(l => l.email && l.email.toLowerCase() === staff.email.toLowerCase());
              if (instances.length > 1) {
                for (const inst of instances) {
                  inst.emailErrorMessage = 'staff.error.email_duplicate';
                }
              }
              if (!/^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(staff.email.toLowerCase())) { // eslint-disable-line no-control-regex, no-useless-escape
                staff.emailErrorMessage = 'staff.error.email';
              }
            }
          }
        }
        else if (this.mode === 'TAG') {
          this.requiredFields = ['name'];
          const inUse = await getTagInUse(list);
          for (const tag of inUse) {
            if (tag.name.length > 0) {
              const found = list.filter(l => l.name && l.name.toLowerCase().trim() === tag.name.toLowerCase());
              if (found.length !== 0) {
                found[0].nameErrorMessage = 'error.not_unique_key';
              }
            }
          }
          
          // check for duplicates in the list
          for (const tag of list) {
            if (tag.name) {
              const instances = list.filter(l => l.name && l.name.toLowerCase() === tag.name.toLowerCase());
              if (instances.length > 1) {
                for (const inst of instances) {
                  inst.nameErrorMessage = 'error.not_unique_key';
                }
              }
            }
          }
        }
        else if (this.mode === 'STAFF_GENERIC') {
          this.requiredFields = ['name', 'payfrequency', 'payamount', 'type', 'location'];
        }
        else if (this.mode === 'RESOURCE') {
          this.requiredFields = ['name', 'cost', 'currency'];
        }
        else if (this.mode === 'LOCATION') {
          this.requiredFields = ['name', 'country'];
          for (const location of list) {
            if (location.country && location.country.length > 2) {
              const code = countryCodes.filter(t => t.text === location.country);
              if (code.length > 0) {
                location.country = code[0].value;
              }
            }
          }          
        }
        else if (this.mode === 'REBATE') {
          this.requiredFields = ['name', 'rebate'];
        }
        else if (this.mode === 'STAGE') {
          this.requiredFields = ['name'];
        }
        else if (this.mode === 'PROJECT') {
          this.requiredFields = ['name', 'currencycode'];
        }
        else if (this.mode === 'COMPANY') {
          this.requiredFields = ['name', 'type'];
          for (const company of list) {
            if (company.type === 'Primary') {
              company.type = null;
            }
          }
        }
        
        if (this.customFields) {
          for (const field of this.customFields) {
            if (field.notNull) {
              this.requiredFields.push(field.name);
            }
          }
        }
        
        this.items = list;
        this.preparing = false;
        this.previewFields = fields;
      },
      getEntity() {
        if (this.mode === 'TASKS') {
          return 'TASK';
        }
        else if (this.mode === 'STAFF_GENERIC') {
          return 'STAFF';
        }
        return this.mode;
      },
      async modalPreview() {
        await this.preparePreview();
        this.state.previewShow = true;
      },
      async getStaffUuids(staffs) {
        let ret = null;
        for (const staff of staffs) {
          const matches = /(^.+?) \((\d+?)%\)$/.exec(staff);

          const fullName = matches !== null ? matches[1] : staff;
          const utilization = matches !== null ? parseInt(matches[2]) / 100 : 1;
          if (fullName in this.staffs) {
            if (this.staffs[fullName].uuId != null) {
              ret = [{ uuId: this.staffs[fullName].uuId, name: fullName, utilization: utilization, resourceLink: {utilization: utilization } }];
            }
          }
          else {
            let filter = [
              [ "STAFF.name", "is", fullName.trim()]
            ];
            let uuId = await staffService.list({ filter: filter }, false, true).then(response => {
              return response.data.length > 0 ? response.data[0].uuId : null;
            })
            .catch(e => {
              console.log(e); // eslint-disable-line no-console
              return [];
            });
            
            this.staffs[fullName] = { uuId: uuId };
            ret = [{ uuId: uuId, name: fullName, utilization: utilization, resourceLink: {utilization: utilization } }];
          }
        }
        return ret;
      },
      toOptionValue(texts, values) {
        const ret = [];
        for (let i = 0; i < texts.length; i++) {
          ret.push({ text: texts[i], value: values[i] });
        }
        return ret;
      },
      async getProjectUuids(project) {
        let ret = null;

        if (project in this.projects) {
          if (this.projects[project].uuId != null) {
            ret = { uuId: this.projects[project].uuId, name: project, stages: this.projects[project].stages, autoScheduling: this.projects[project].autoScheduling };
          }
        }
        else {
          let filter = [
            [ "PROJECT.name", "is", project.trim()]
          ];
          let result = await projectService.list({ filter: filter }).then(response => {
            return response.data.length > 0 ? { uuId: response.data[0].uuId, color: response.data[0].color, stages: this.toOptionValue(response.data[0].stages, response.data[0].stages), autoScheduling: response.data[0].autoScheduling } : { uuId: null, stages: null, autoScheduling: false };
          })
          .catch(e => {
            console.log(e); // eslint-disable-line no-console
            return { uuId: null, stages: null, autoScheduling: false };
          });
          
          this.projects[project] = { uuId: result.uuId, stages: result.stages, autoScheduling: result.autoScheduling, color: result.color };
          if (result.uuId != null) {
            ret = { uuId: result.uuId, name: project, stages: result.stages, autoScheduling: result.autoScheduling, color: result.color };
          }
        }
        return ret;
      },
      async getCompanyUuid(name) {
        let ret = null;
        if (name in this.companies) {
          if (this.companies[name]) {
            ret = { uuId: this.companies[name], name: name };
          }
        }
        else {
          const uuId = await companyService.list({ filter: name }).then(response => {
            return response.data.length > 0 ? response.data[0].uuId : null;
          })
          .catch(e => {
            console.log(e); // eslint-disable-line no-console
            return [];
          });
          const entry = {uuId: uuId, name: name};
          this.companies[name] = uuId;
          if (uuId !== null) {
            ret = entry;
          }
        }
        return ret;
      },
      async getLocationUuid(location) {
        let ret = null;
      
        if (location in this.locations) {
          ret = { uuId: this.locations[location].uuId, name: this.locations[location].name };
        }
        else {
          const loc = await locationService.list({ filter: location }).then(response => {
            return response.data.length > 0 ? response.data[0] : null;
          })
          .catch(e => {
            console.log(e); // eslint-disable-line no-console
            return [];
          });
          const entry = {uuId: loc !== null ? loc.uuId : null, name: loc !== null ? loc.name : null};
          
          this.locations[location] = entry;
          if (loc !== null) {
            ret = entry;
          }
        }
      
        return ret;
      },
      async getCustomerUuid(customer) {
        let ret = null;
      
        if (customer in this.customers) {
          ret = { uuId: this.customers[customer], name: customer };
        }
        else {
          const uuId = await customerService.list({ filter: customer }).then(response => {
            return response.data.length > 0 ? response.data[0].uuId : null;
          })
          .catch(e => {
            console.log(e); // eslint-disable-line no-console
            return [];
          });
          const entry = {uuId: uuId, name: customer};
          this.customers[customer] = uuId;
          ret = entry;
        }
      
        return ret;
      },
      async getDepartmentUuids(depts, company /** , companyUuid*/) {
        const ret = [];
        for (const dept of depts) {

          if (`${company}/${dept}` in this.departments) {
            ret.push({ uuId: this.departments[`${company}/${dept}`], name: dept });
          }
          else {
            const filter = [
              ['COMPANY.DEPARTMENT.name', 'regex', dept]
            ];
            if (company) {
              filter.push(['COMPANY.name', 'regex', company]);
            }
            const uuId = await departmentService.companyLookup({ filter: filter }).then(response => {
              return response.data.length > 0 ? response.data[0].uuId : null;
            })
            .catch(e => {
              console.log(e); // eslint-disable-line no-console
              return [];
            });
            const entry = {uuId: uuId, name: dept};
            this.departments[`${company}/${dept}`] = uuId ;
            ret.push(entry);
          }
        }
        return ret;
      },
      async getStageUuids(stages) {
        const ret = [];
        for (const stage of stages) {

          if (stage in this.stages) {
            ret.push({ uuId: this.stages[stage].uuId, name: stage, color: this.stages[stage].color });
          }
          else {
            const result = await stageService.list({ filter: stage }).then(response => {
              return response.data.length > 0 ? { uuId: response.data[0].uuId, color: response.data[0].color } : { uuId: null, color: null };
            })
            .catch(e => {
              console.log(e); // eslint-disable-line no-console
              return [];
            });
            const entry = {uuId: result.uuId, name: stage, color: result.color};
            this.stages[stage] = { uuId: result.uuId, color: result.color } ;
            ret.push(entry);
          }
        }
        return ret;
      },
      validateLevel(level) {
        if (level != null && Object.hasOwn(this.enumList, 'SkillLevelEnum')) {
          const list = this.enumList.SkillLevelEnum
          const found = list.find(i => i.value.toLowerCase() == level.toLowerCase())
          if (found != null) {
            return found.value
          }
        }
        return null
      },
      async getSkillUuids(skills) {
        const ret = [];
        for (const skill of skills) {
          const matches = /(^.+?) \((.+?)\)$/.exec(skill);
          if (matches !== null) {
            const name = matches[1];
            const level = this.validateLevel(matches[2]); 
            if (name in this.skills) {
              if (this.skills[name].uuId !== null && level != null) {
                ret.push({ uuId: this.skills[name].uuId, name: skill, skillLink: {level: level }, level: level });
              }
            }
            else {
              const uuId = await skillService.list({ filter: name.trim() }).then(response => {
                return response.data.length > 0 ? response.data[0].uuId : null;
              })
              .catch(e => {
                console.log(e); // eslint-disable-line no-console
                return [];
              });
              
              const entry = {uuId: uuId, name: name, skillLink: {level: level}, level: level};
              this.skills[name] = { uuId: uuId };
              if (uuId !== null && level != null) {
                ret.push(entry);
              }
            }
          }
        }
        return ret;
      },
      async getRebateUuids(rebates) {
        const ret = [];
        for (const rebate of rebates) {
          const matches = /(^.+?)\((.+?)\)$/.exec(rebate);
          if (matches !== null) {
            const name = matches[1];
            
            if (name in this.rebates && this.rebates[name].uuId != null) {
              ret.push({ uuId: this.rebates[name].uuId, name: rebate });
            }
            else {
              const uuId = await rebateService.list({ filter: name }).then(response => {
                return response.data.length > 0 ? response.data[0].uuId : null;
              })
              .catch(e => {
                console.log(e); // eslint-disable-line no-console
                return [];
              });
              const entry = {uuId: uuId, name: rebate};
              
              this.rebates[name] = entry;
              if (entry.uuId != null) {
                ret.push(entry);
              }
            }
          }
        }
        return ret;
      },
      async getResourceUuids(resources) {
        const ret = [];
        for (const resource of resources) {
          const matches = /(^.+?) \((\d+?)\)|(^.+?)$/.exec(resource);
          if (matches !== null) {
            const name = matches[1];
            const resourceUnit = parseInt(matches[2]);
          
            if (name in this.resources) {
              ret.push({ uuId: this.resources[name].uuId, name: resource, quantity: resourceUnit, resourceLink: {resourceUnit: resourceUnit} });
            }
            else {
              const uuId = await resourceService.list({ filter: name }).then(response => {
                return response.data.length > 0 ? response.data[0].uuId : null;
              })
              .catch(e => {
                console.log(e); // eslint-disable-line no-console
                return [];
              });
              this.resources[name] = { uuId: uuId };
              ret.push({ uuId: uuId, name: resource, quantity: resourceUnit, resourceLink: {resourceUnit: resourceUnit } });
            }
          }
        }
        return ret;
      },
      getParentId(key, idx, map) {
        var parentId = null;
        if (typeof this.summaryTasks[key] !== 'undefined' &&
            this.summaryTasks[key].parentTask !== null &&
            typeof this.summaryTasks[key].parentTask !== 'undefined') {
          const tasks = map[this.summaryTasks[key].parentTask];
          if (typeof tasks !== 'undefined') {
            for (const task of tasks) {
              if (task.index <= idx) {
                parentId = task.id;
              }
            }
          }
        }
        return parentId;
      },
      async modalPreviewOk(result) {
        this.state.previewShow = false;
        this.submitting = true;
        this.canPreview = false;
        this.submitUpdate();
        const columns = result.columns;
        this.items = result.items;
         
        // put the child items in the 'items' under the parent
        const items = [];
        const map = {};
        // fill the map first
        for (var mapidx = 0; mapidx < this.items.length; mapidx++) {
          map[this.items[mapidx].id] = this.items[mapidx];
        }
        
        for (var idx = 0; idx < this.items.length; idx++) {
          const item = this.items[idx];

          if (typeof item.parent === 'undefined') {
            items.push(item);
          }
          else {
            const parent = map[item.parent];
            if (parent) {
              if (typeof parent.items === 'undefined') {
                parent.items = [item];
              }
              else {
                parent.items.push(item);
              }
            }
          }
          
          map[item.id] = item;
          
          if (item.name && item.name.length > 200) {
            item.name = item.name.substr(0, 200);
          }
          
          // set the duration if not set
          if (item.type === 'Task' &&
              !item.duration) {
            item.duration = '1D';   
          }
          
          if (item.staffs != null && columns.includes('staffs')) {
            item.staffList = item.staffs;
            delete item.staffs;
            columns.push('staffList');
          }
          
          if (item.skills != null && columns.includes('skills')) {
            item.skillList = item.skills;
            columns.push('skillList');
          }
          
          if (item.resources != null && columns.includes('resources')) {
            item.resourceList = item.resources;
            for (let r = item.resourceList.length - 1; r >= 0; r--) {
              if (typeof item.resourceList[r].unit !== 'undefined') {
                item.resourceList[r].quantity = item.resourceList[r].unit;
              }
              
              if (item.resourceList[r].uuId === null) {
                item.resourceList.pop();
              }
            }
            columns.push('resourceList');
          }
          
          if (item.notes && columns.includes('notes')) {
            const noteList = item.notes.split('\n');
            item.notes = [];
            for (const note of noteList) {
              item.notes.push({ text: note });
            }
          }
          
          if (typeof item.workflow_stage !== 'undefined' && columns.includes('workflow_stage')) {
            if (this.projectId) {
              for (var stage_idx = 0; stage_idx < this.stages.length; stage_idx++) {
                if (this.stages[stage_idx].name === item.workflow_stage) {
                  item.stage = this.stages[stage_idx].uuId;
                  columns.push('stage');
                  break;
                }
              }
            }
            else {
              const index = item.project.stages.findIndex(s => s === item.workflow_stage);
              if (index !== -1) {
                item.stage = item.project.stagesUuId[index];
                columns.push('stage');
              }
            } 
          }
          
          if (typeof item.currency !== 'undefined') {
            const valid = await this.validateCurrency(item.currency);
            if (!valid) {
              delete item['currency'];
            }
          }
          
          if (typeof item.complexity !== 'undefined') {
            const valid = await this.validateComplexity(item.complexity);
            if (!valid) {
              delete item['complexity'];
            }
          }
          
          if (typeof item.priority !== 'undefined') {
            const valid = await this.validatePriority(item.priority);
            if (!valid) {
              delete item['priority'];
            }
          }
          
          if (typeof item.stage !== 'undefined') {
            const valid = this.validateStage(item.stage);
            if (!valid) {
              delete item['stage'];
            }
          }
          
          if (item.constraint) {
            item.constraintType = item.constraint.type;
            columns.push('constraintType');
            columns.push('constraintTime');
            item.constraintTime = item.constraint.time;
          }
          
          // delete hidden fields
          for (const key of Object.keys(item)) {
            if (key !== 'items' && key !== 'uuId' && !columns.includes(key) && 
                !(this.mode === 'PREDECESSOR' && (key === 'toUuId' || key === 'fromUuId'))) { // keep the child items

              delete item[key];
            }
          }
        }
      
        this.$emit("modal-ok", {
          items: cloneDeep(items),
          map: cloneDeep(map)
        })
      },
      async validateCurrency(currency) {
        if (currency != null && Object.hasOwn(this.enumList, 'CurrencyEnum')) {
          const list = this.enumList.CurrencyEnum
          return list.find(i => i.value == currency) != null
        }
        return false
      },
      async validateComplexity(complexity) {
        if (complexity != null && Object.hasOwn(this.enumList, 'GanttComplexityEnum')) {
          const list = this.enumList.GanttComplexityEnum
          return list.find(i => i.value == complexity) != null
        }
        return false
      },
      async validatePriority(priority) {
        if (priority != null && Object.hasOwn(this.enumList, 'GanttPriorityEnum')) {
          const list = this.enumList.GanttPriorityEnum
          return list.find(i => i.value == priority) != null
        }
        return false
      },
      validateStage(stage) {
        return -1 !== this.stages.findIndex(s => { return s.uuId === stage });
      },
      async getSchema() {
    
        this.schema = await managementService.info({ type: 'model' }).then((response) => { 
          const data = response.data[response.data.jobCase];
          return data;
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return [];
        });
      },
      modalPreviewCancel() {
        this.state.previewShow = false;
      },
      
      onGridReady(params) {
        this.rowData = null;
        this.gridApi = params.api;
      },
      onRangeSelectionChanged: debounce(function(event) {
        //if (event.finished) {
          var cellRanges = this.gridApi.getCellRanges();
          if (!cellRanges || cellRanges.length === 0) {
            return;
          }
          this.startRow = cellRanges[0].startRow.rowIndex + 1;
          this.endRow = cellRanges[0].endRow.rowIndex + 1;
          // support dragging from bottom to top
          if (this.startRow > this.endRow) {
            const start = this.startRow;
            this.startRow = this.endRow;
            this.endRow = start;
          }
          this.gridApi.redrawRows([]);
        //}
      }, 500),
      async getEnumList() {
        this.$store.dispatch('data/enumList').then(enumResp => {
          if (enumResp != null) {
            if (enumResp.jobCase != null && enumResp[enumResp.jobCase] != null) {
              const propertyList = enumResp[enumResp.jobCase]
              const keys = Object.keys(propertyList);
              for (const k of keys) {
                const obj = propertyList[k]
                const codes = Object.keys(obj)
                const list = []
                for (const c of codes) {
                  if (obj[c] < 0) {
                    continue
                  }
                  list.push({ value: c, text: c, num: obj[c] })
                }
                this.$set(this.enumList, k, list)
              }
            }
          }
        }).catch(e => {
          console.error(e) //eslint-disable-line no-console
        })
      },
      getSheetNameOptionLabel(value) {
        return this.sheetNames().find(i => i.value === value)?.text || value;
      }
    },
    beforeMount() {
      
      this.gridOptions = {};
      this.columnDefs = [];
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        lockPosition: true,
        suppressMovable: true,
        editable: true,
        tooltipComponent: 'plainTooltip'
      };
      this.context = {
        componentParent: this
      };
      this.handleDebouncedRangeSelectionChanged = debounce(this.onRangeSelectionChanged, 100);
    },
    created() {
      //Define non-reactive variables under this component
      this.taskThreshold = 1000;
      
      if (this.properties) {
        this.headerComponentOptions = cloneDeep(this.properties);
        this.headerComponentOptions.unshift({ value: null, text: 'None' });
      }
      else {
        this.headerComponentOptions = null;
      }
      this.getEnumList()
    },
    beforeDestroy() {
      // Nullify defined variable
      this.taskThreshold = null;
      this.gridApi = null;
    }
  }
</script>
<style scoped>
.widget-box {
  height: calc(100vh - 77px);
}
.layout-box {
  height: 700px;
}

</style>
<style  lang="scss">
.mapper-height {
  height: 600px;
}
@media only screen and (max-height: 900px) {
  .mapper-height {
    height: 550px;
  }
}
@media only screen and (max-height: 800px) {
  .mapper-height {
    height: 450px;
  }
}
@media only screen and (max-height: 700px) {
  .mapper-height {
    height: calc(100vh - 280px);
  }
}

.tab-header > .dhx_layout-cell-header {
  background-color: var(--gantt-import-tab-header-bg);
}

.sheet-combobox > label {
  margin-bottom: 0;
}

.toolbar_wrapper{
  display: none !important;
}

.custom-spreadsheet {
  margin-bottom: 12px;
}

.custom-tabbar {
  margin-top: 12px;
}

.dhx_widget.dhx_popup {
  z-index: 1100 !important;
}


.gantt-import-mapper {
  display: none;
  &.dBlock {
    display: block;
  }
}

.mh-50 {
  max-height: 50%;
}

.submit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay);
}

.spinner {
  margin-left: 50%;
  margin-top: 25%;
}

.import-controls {
  display: flex; 
  justify-content: flex-end;
  margin-bottom: 0;
  height: 40px;
  font-size: 18px;    
  position: relative;
  align-items: center;
}

.parent-apply-to-all {
  margin-top: 15px;
}
</style>
