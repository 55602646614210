<template>
  <div class="edit-cell">
    <span>{{ label }}</span>
    <!-- task template selector -->
    <GenericSelectorModalForAdmin v-if="selectorShow"
      :show.sync="selectorShow" 
      :entityService="templateTaskUtil"
      entity="TEMPLATE__PROJECT"
      nonAdmin
      @ok="selectorOk"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { templateTaskUtil } from '@/views/management/script/taskTemplate';

export default Vue.extend({
  name: 'TaskTemplateEditor',
  components: {
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  data() {
    return {
      selectorShow: true,
      value: null
    };
  },
  watch: {
    selectorShow(newValue) {
      if(!newValue) {
        this.closePicker();
      }
    }
  },
  methods: {
    /** Reserved methods START */
    getValue() {
      return this.value;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    /** Reserved methods END */

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    selectorOk({ details }) {
      if(details.length > 0 && this.value.uuId != details[0].uuId) {
        const newIds = details.map(i => i.uuId);
        const newNames = details.map(i => i.name)
        
        this.value.uuIds = newIds;
        
        if(this.value.names != null) {
          this.value.names.splice(0, this.value.names.length, ...newNames);
        } else {
          this.value.names = newNames;
        }
        
        this.params.onChange({ 
          uuIds: cloneDeep(newIds)
          , names: cloneDeep(newNames)
          , details: cloneDeep(details)
          , taskId: this.params.data? this.params.data.uuId: null
          , params: this.params });
      }
      this.closePicker();
    },
    selectorCancel() {
      this.closePicker();
    },
    prepareValue() {
      if (typeof this.params.value === 'object') {
        this.value = this.params.value;
        return;
      } else if (typeof this.params.value === 'string') {
        this.value = { uuIds: this.params.value };
      } else {
        this.value = { uuIds: null, names: null }
      }
    }
  },
  created() {
    this.templateTaskUtil = templateTaskUtil;
    this.prepareValue();
  },
  beforeDestroy() {
    this.templateTaskUtil = null;
  },
  mounted() {

  },
  computed: {
    label() {
      return this.value && this.value.names? this.value.names.join(', '): '';
    }
  }
})
</script>