var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("StageBadgeGroupModal", {
    attrs: { show: _vm.modalShow, stageList: _vm.stageList, multiple: "" },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.stageSelectorOk,
      cancel: _vm.stageSelectorCancel,
      stageDeleted: _vm.stageSelectorDeleted,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }