var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.type === "text"
    ? _c("span", [_vm._v(_vm._s(_vm.label))])
    : _vm.type === "img"
    ? _c("img", { staticClass: "img-contain", attrs: { src: _vm.value } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }