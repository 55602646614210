var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.companySelectorShow
        ? _c("CompanySelectorModalForAdmin", {
            attrs: {
              show: _vm.companySelectorShow,
              preselected: _vm.value ? _vm.value.uuId : null,
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.companySelectorShow = $event
              },
              cancel: _vm.companySelectorCancel,
              ok: _vm.companySelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }