var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.departmentSelectorShow
        ? _c("DepartmentSelectorModalForAdmin", {
            attrs: {
              show: _vm.departmentSelectorShow,
              preselected: _vm.value,
              company: _vm.company,
              selectorTitle: _vm.$t("department.title_selector"),
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.departmentSelectorShow = $event
              },
              cancel: _vm.departmentSelectorCancel,
              ok: _vm.departmentSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }