<template>
  <div>
    <b-modal v-model="modalShow" size="lg" :title="title" footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      content-class="shadow"
      no-close-on-backdrop
    >
      <div>
        <button id="IMPORT_DOC_CLEAR_ALL" class="del-button btn-action mb-2" @click="clearAll"><font-awesome-icon :icon="['far', 'trash-can']"/></button>
        <b-popover target="IMPORT_DOC_CLEAR_ALL" triggers="hover" placement="top">
          {{ $t('button.clear_all') }}
        </b-popover>
      </div>
          
      <label>{{ $t('task.import_settings.field') }}</label>
      <b-input-group :prepend="displayValue">
        <multiselect v-model="valueObj" class="custom-dropdown-options enable-option-icon field-dropdown-width-fix"
          :max-height="1000"
          :options="sanitizedFieldOpts"
          track-by="value" 
          label="text"
          :custom-label="getFieldOptionLabel"
          :placeholder="''"
          :searchable="false" 
          :allow-empty="false"
          :showLabels="false"
          @input="onValueChange">
          <template slot="option" slot-scope="props">
            <template v-if="isGroupHeader(props.option)">
              <span class="option__title option__group_header">{{ getFieldOptionLabel(props.option) }}</span>
            </template>
            <template v-else>
              <font-awesome-icon class="selected-option-icon" v-if="valueObj?.value == props.option.value" :icon="['far', 'check']" />
              <template v-if="isGroupOption(props.option)">
                <span class="option__title option__group_option">{{ getFieldOptionLabel(props.option) }}</span>
              </template>
              <template v-else>
                <span class="option__title">{{ getFieldOptionLabel(props.option) }}</span>
              </template>
            </template>
          </template>
        </multiselect>
      </b-input-group>
      <label v-if="value !== null && typeof settingsData[value] !== 'undefined'" class="mt-4">{{ $t('task.import_settings.keywords') }}</label>
      <button v-if="value !== null && typeof settingsData[value] !== 'undefined'" :id="`ADD_${id}`" class="btn-action" @click="add"><font-awesome-icon :icon="['far', 'plus']"/>
        <b-popover
          :target="`ADD_${id}`"
          placement="top"
          triggers="hover"
          :content="$t('button.add')">
        </b-popover>
      </button>
      <BadgeGroup v-if="value !== null && typeof settingsData[value] !== 'undefined' && settingsData[value] !== null" v-model="settingsData[value]" class="mb-3">
        <template v-slot:default="{ item, index }">
          <Badge @badgeRemove="remove(index)" @badgeClick="edit(index)"
            :text="item.name" 
            :attribute="` (${item.weight})`"
            :icon="item.direction === 'v' ? 'arrows-up-down' : 'arrows-left-right'"
            variant="white" 
            :pillable="typeof item !== 'undefined' && !!item.pillable" :key="index" />
          </template>
      </BadgeGroup>
      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.import_settings.add')"
        v-model="editShow"
        size="sm"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="editOk"
        >
        
      <b-form-group :label="$t('task.import_settings.value')" label-for="axestitle">
        <b-input-group>
          <b-form-input type="text"
            v-model="editVal.name" trim>
          </b-form-input>
        </b-input-group>
      </b-form-group>   
      
      <b-form-group :label="$t('task.import_settings.weight')" label-for="weight">
        <b-input-group>
          <b-form-input type="number"
            v-model="editVal.weight"
            min="0" trim>
          </b-form-input>
        </b-input-group>
      </b-form-group>   
      
      <b-form-group v-if="value !== 'summary_name' && value !== 'milestone_name' && value !== 'name'" :label="$t('task.import_settings.direction')" label-for="direction">
        <multiselect v-model="editVal.direction" class="custom-dropdown-options enable-option-icon"
          :max-height="300"
          :options="directionOptions.map(i => i.value)"
          :custom-label="getDirectionOptionLabel"
          :placeholder="''"
          :searchable="false" 
          :allow-empty="false"
          :showLabels="false">
          <template slot="option" slot-scope="props">
            <font-awesome-icon class="selected-option-icon" v-if="editVal.direction == props.option" :icon="['far', 'check']" />
            <span class="option__title">{{ getDirectionOptionLabel(props.option) }}</span>
          </template>
        </multiselect>
      </b-form-group>   
      
      <b-form-group v-if="value !== 'summary_name' && value !== 'milestone_name' && value !== 'name'" :label="$t('task.import_settings.owner')" label-for="owner">
        <b-input-group>
          <b-form-input type="text"
            v-model="editVal.owner"
            trim>
          </b-form-input>
        </b-input-group>
      </b-form-group>   
      
      <b-form-group v-if="editVal.direction === 'v' || value === 'summary_name' || value === 'milestone_name' || value === 'name'" :label="$t('task.import_settings.parent')" label-for="parent">
        <b-input-group>
          <b-form-input type="text"
            v-model="editVal.parent"
            trim>
          </b-form-input>
        </b-input-group>
      </b-form-group>   
      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button :disabled="editVal.name === '' || editVal.name === null || editVal === null" size="sm" variant="success" @click="editOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="confirmDeleteOk"
        >
      <div class="d-block">
        {{ deleteMessage }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmDeleteOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { fieldOptions, initFieldOptions } from '@/selectOptions';
import Multiselect from 'vue-multiselect';
export default {
  name: 'ImportDocSettingsModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    Multiselect
  },
  props: {
    show:         { type: Boolean, required: true },
    settings:     { type: Object, default: null },
    properties:   { type: Array, default: null },
    title:        { type: String, default: () => { this.$t('task.import_settings.title') } }
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 9),
      modalShow: false,
      value: null,
      valueObj: null,
      settingsData: null,
      options: fieldOptions,
      directionOptions: [
        { value: 'h', text: 'Create from row' },
        { value: 'v', text: 'Create from column' }
      ],
      editShow: false,
      editVal: { name: null, weight: 0, owner: null, parent: null },
      editIndex: -1,
      confirmDeleteShow: false
    }
  },
  created() {
    if (this.properties === null) {
      initFieldOptions(this);
    }
    else {
      this.options = this.properties;
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        if (newValue) {
          if (this.properties === null) {
            this.options = fieldOptions;
            initFieldOptions(this);
          }
          else {
            this.options = this.properties;
          }
          this.settingsData = cloneDeep(this.settings);
          if (this.properties === null) {
            this.value = 'summary_name';
          }
          else {
            this.value = this.properties[0].value;
          }
          this.$nextTick(() => {
            this.valueObj = this.sanitizedFieldOpts.find(i => i.value == this.value);
          });
          
          // populate the field if it is empty
          if (this.value !== null &&
              (typeof this.settingsData[this.value] === 'undefined' ||
              this.settingsData[this.value] === null)) {
            this.settingsData[this.value] = [];
          }
        }
      }
    }
  },
  computed: {
    displayValue() {
      if (this.properties) {
        return '';  // The values are not tasks
      }
      
      if (typeof this.value === 'undefined' ||
          this.value === null) {
        return this.value;
      }
      
      if (this.value.startsWith('summary')) {
        return this.$t('task_type.Project');
      }
      else if (this.value.startsWith('milestone')) {
        return this.$t('task_type.Milestone');
      }
      return this.$t('task_type.Task');
    },
    deleteMessage() {
      return this.$t('confirmation.delete_settings');
    },
    sanitizedFieldOpts() {
      const list = [];
      if (this.options == null) {
        return list;
      }
      if (this.options.length == 0) {
        return list;
      }
      let hasNullOption = false;
      let groupIndex = 0;
      //Vue-multiselect's option Group feature doesn't support the mix of simple option and group option
      //Custom logic is created to achieve the goal.
      for (let i = 0, len = this.options.length; i < len; i++) {
        if (Array.isArray(this.options[i].options)) {
          //// Process group list
          //1) generate a dummy (disabled) option with class/style as group header 
          list.push({
            text: this.options[i].label
            , value: `group_idx_${groupIndex++}`
            , groupHeader: true
            , $isDisabled: true //Make it disabled
          });
          //2) populate group options
          for (let j = 0, len = this.options[i].options.length; j < len; j++) {
            list.push({
              ...this.options[i].options[j], group: true
            })
          }
        } else if (this.options[i].value == null && !hasNullOption) {
          list.push({ ...this.options[i], value: 'null' });
          hasNullOption = true;
        } else {
          list.push(this.options[i]);
        }
      }
      return list;
    }
  },
  methods: {
    add() {
      this.editVal = { name: null, weight: 0, direction: 'h', owner: null, parent: null };
      this.editIndex = -1;
      this.editShow = true;
    },
    remove(index) {
      this.settingsData[this.value].splice(index, 1);
    },
    edit(index) {
      this.editIndex = index;
      this.editVal = this.settingsData[this.value][index];
      this.editShow = true;
    },
    editOk() {
      if (this.editIndex !== -1) {
        this.settingsData[this.value].splice(this.editIndex, 1, this.editVal);
      }
      else {
        this.settingsData[this.value].push(this.editVal);
      }
      this.editShow = false;
    },
    ok() {
        this.$emit('update:show', false);
        this.$emit('success', this.settingsData );
    },
    onValueChange(value) {
      this.value = value.value == 'null' ? null : value.value;
      if (this.value !== null &&
          (typeof this.settingsData[this.value] === 'undefined' ||
          this.settingsData[this.value] === null)) {
        this.$set(this.settingsData, this.value, []);
      }
    },
    clearAll() {
      this.confirmDeleteShow = true;
    }, 
    confirmDeleteOk() {
      this.confirmDeleteShow = false;
      for (const key of Object.keys(this.settingsData)) {
        if (key !== 'uuId' && key !== 'mode') {
          this.settingsData[key] = [];
        }
      }
    },
    getFieldOptionLabel(value) {
      return value?.text != null? value.text : value;
    },
    getDirectionOptionLabel(value) {
      return this.directionOptions.find(i => i.value === value)?.text || value;
    },
    isGroupHeader(value) {
      return value?.groupHeader == true;
    },
    isGroupOption(value) {
      return value?.group == true;
    }
  }
}
</script>
<style lang="scss">
.del-button {
  position: relative;
  left: -0.5rem;
}
.field-dropdown-width-fix {
  flex: 1 1 auto;
  width: 1% !important;
}
</style>
