<template>
  <div>
    <b-modal id="file-to-import-modal"
        no-close-on-backdrop
        content-class="shadow"
        size="md"
        :visible="show"
        @hidden="modalCancel"
      >
      <div slot="modal-title" class="w-100" v-html="title"></div>
      <b-tabs content-class="mt-3" pills>
        <b-tab :title="$t('task.import.file')">
          <b-form-group id="field-file" label="File" label-for="file-import">
            <b-form-file
              
              id="file-import"
              v-model="file"
              :state="state.pristine? null : Boolean(file)"
              :placeholder="$t('task.import.choose')"
              drop-placeholder="Drop file here..."
              :browse-text="$t('task.import.browse')"
            ></b-form-file>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFileValidationError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('task.alert.filetype_not_supported') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-tab>
        <b-tab :title="$t('task.import.url_title')">
          <b-form-group id="field-url" :label="$t('task.import.url_title')" label-for="file-url-import">
            <b-form-input
              
              id="file-url-import"
              v-model="url"
              :placeholder="$t('task.import.url')"
            ></b-form-input>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showUrlError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-tab>
      </b-tabs>
            
      <template v-slot:modal-footer="{ cancel }">
        <b-spinner v-if="downloading" small />
        <b-button size="sm" variant="success" :disabled="showFileValidationError || (file === null && url === null) || downloading" @click="modalOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- Import Mapper Modal -->
    <GanttImportMapperDialog v-if="show" :show="state.importMapperShow"
      :source="importFile"
      :projectId="projectId"
      :properties="properties"
      :existingData="existingData"
      :mode="mode"
      @modal-ok="importMapperOk"
      @modal-cancel="importMapperCancel">
    </GanttImportMapperDialog>

    <GanttErrorDialog
      @modal-ok="errorDialogOk"
      :show="state.errorDialogShow"
      :msg="errorDialogMessage" />
  </div>
</template>

<script>

import { fileService } from "@/services";

import GanttImportMapperDialog from "./GanttImportMapperDialog";
import GanttErrorDialog from "./GanttErrorDialog.vue";
/**
 * Dialog to let user select a file.
 * 1) The import field is mandatory. Click 'Load' button while leaving it blank will trigger validation error.
 * 2) A successful Load button click will toggle Gantt ImportMapper Dialog
 * 3) Gantt Import Mapper Dialog will emit modal-ok with payload containing items and map properties.
 *    The payload will be further emitted back to parent component.
 */
export default {
  name: "GanttImportDialog",
  components: {
    GanttImportMapperDialog,
    GanttErrorDialog
  },
  inheritAttrs: false,
  props: {
    projectId: { type: String, default: null },
    show:     { type: Boolean, default: false },            // Toggle true to show import dialog, or false to hide it.
    title:    { type: String, default: "Import Document" }, // Import dialog title.
    // parentId: { type: String, required: true },             // Parent/root Id for imported task with type "project".
    // gantt:    { type: Object, required: true }              // Gantt instance.
    properties: { type: Array, default: null },
    mode: { type: String, default: 'TASKS' },
    existingData: { type: Array, default: null }
  },
  data: function() {
    return {
      state: {
        importMapperShow: false,  // Toggle true to show importMapper dialog, or false to hide it.
        pristine: true,           // Flag to avoid validation error appears prematurely when dialog just displays.
        errorShow: false,          // Toggle true to show validation error, or false to hide it.
        errorDialogShow: false
      },
      file: null,                 // File object to hold user selected file.
      url: null,
      importFile: null,
      errorDialogMessage: null,
      alertMsg: null,
      googleSheets: false,
      downloading: false
    }
  },

  watch: {
    // eslint-disable-next-line
    file: function(newValue) {
      this.state.pristine = false;
    }
  },

  computed: {
    showFileValidationError() {
      return !this.validateFileType() || this.hasFieldValidationError(this.errors, "file");
    },
    showUrlError() {
      return this.alertMsg !== null;
    }
  },
  methods: {
    hasFieldValidationError(errors, key) {
      return errors.first(key) && errors.first(key).length? true: false;
    },
    validateFileType() {
      //    accept=".pdf, .xls, .xlsx, .ods"
      const name = this.file !== null ? this.file.name : '';
      return name === '' ||
             name.endsWith('.pdf') || 
             name.endsWith('.xls') || 
             name.endsWith('.xlsx') ||
             name.endsWith('.ods') ||
             name.endsWith('.csv');
    },
    modalOk() {
      const self = this;

      if (this.url) {
        if ((this.url.indexOf('http://') === -1 &&
            this.url.indexOf('https://') === -1) ||
            this.url === 'https://' ||
            this.url === 'http://') {
          // no protocol in url
          this.alertMsg = this.$t('task.import.invalid_url');
          return;    
        }
        
        self.googleSheets = self.url.indexOf("docs.google.com/spreadsheets") !== -1;
        // get an excel spreadsheet
        if (self.googleSheets && self.url.lastIndexOf('edit') !== -1) {
          self.url = self.url.substr(0, self.url.lastIndexOf('edit'));
          self.url += 'export?format=xlsx';
        }
        this.alertMsg = null;
        this.downloading = true;
        fileService.downloadUrl(this.url).then((response) => {
          
          if (response.headers['content-type'] !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
              response.headers['content-type'] !== 'application/vnd.oasis.opendocument.spreadsheet' &&
              response.headers['content-type'] !== 'text/csv' &&
              !response.headers['content-type'].startsWith('application/pdf')) {
            // if this is an html document then check to see why it is html
            if (response.headers['content-type'].indexOf('text/html') !== -1) {
              var enc = new TextDecoder("utf-8");
              const content = enc.decode(response.data);
              // try to get the title
              if (content) {
                var mtch = content.match(/<table.*?>[\s\S]*?<\/table>/gi);
                if (!mtch) {
                  // no tables
                  self.alertMsg = self.$t('task.import.no_tables');
                }
                
                var match = content.match(/\/signin\/rejected\?/);
      
                if(match) {
                  self.alertMsg = self.$t('task.import.permission');
                }
              }
            }
            
            if (self.alertMsg === null) {      
              // images are not supported
              self.alertMsg = self.$t('task.import.invalid_file');
            }
          }
          
          let metadata = {
            type: response.headers['content-type']
          };
          
          // try to get the filename from the URL
          let filename = self.url.substr(self.url.lastIndexOf('/') + 1);
          
          if (response.headers['content-disposition']) {
            let header = response.headers['content-disposition'];
            if (header.indexOf('filename="') !== -1) {
              filename = header.substr(header.indexOf('filename=') + 10);
              filename = filename.substr(0, filename.indexOf('"')); 
            }
            else if (header.indexOf('filename=') !== -1) {
              filename = header.substr(header.indexOf('filename=') + 9);
            }
            
            if (self.googleSheets) {
              // remove the .xlsx
              filename = filename.substr(0, filename.length - 5);
            }
          }
                    
          if (this.alertMsg === null) {
            let file = new File([response.data], filename, metadata);
            self.importFile = file;
            self.state.importMapperShow = true;
          }
          this.downloading = false;
        })
        .catch(e => {
          if (e.response && e.response.status === 404) {
            self.alertMsg = self.$t('task.import.not_found');
          }
          else {
            self.alertMsg = e;
          }
          this.downloading = false;
        });
      }
      else {
        this.$validator.validate().then(valid => {
          if (valid && this.validateFileType()) {
            self.importFile = self.file;
            self.googleSheets = false;
            self.state.importMapperShow = true;
          } else {
            this.state.errorShow = true;
          }
        });
      }
    },
    modalCancel() {
      this.reset();
      this.$emit("modal-cancel");
    },
    importMapperOk({ items, map }) {
      this.reset();
      this.state.importMapperShow = false;
      this.$emit("modal-ok", { items, map });
    },
    importMapperCancel() {
      this.state.importMapperShow = false;
      this.modalCancel();
    },
    errorDialogOk() {
      this.state.errorDialogShow = false;
    },
    reset() {
      this.file = null;
      this.alertMsg = null;
      this.googleSheets = false;
      this.url = null;
      this.importFile = this.file;
      this.state.pristine = true;
      this.$validator.reset();
    }
  }
}
</script>

