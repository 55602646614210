var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "widget-box" },
    [
      _c(
        "b-modal",
        {
          staticClass: "container",
          attrs: {
            size: "xl",
            title: _vm.title,
            id: "preview-modal",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            visible: _vm.show,
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "sm",
                        variant: "success",
                        disabled: _vm.disableImport,
                      },
                      on: { click: _vm.modalConfirm },
                    },
                    [_vm._v("Import")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.modalCancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: "danger",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c("TaskTree", {
            attrs: {
              projectId: _vm.projectId,
              height: "calc(100vh - 300px)",
              taskData: _vm.source,
              mode: _vm.mode,
              enableDelete: true,
              preview: true,
              fields: _vm.fields,
              customFields: _vm.customFields,
              requiredFields: _vm.requiredFields,
              staffs: _vm.staffs,
              skills: _vm.skills,
              resources: _vm.resources,
              multiple: true,
              existingData: _vm.existingData,
              title:
                _vm.projectId !== null
                  ? _vm.$t("task.title_selector")
                  : _vm.mode === "STAFF"
                  ? _vm.$t("staff.field.lastName")
                  : _vm.$t("document.name"),
              properties: _vm.properties,
            },
            on: {
              stages: _vm.onStages,
              columnChange: _vm.onColumnChange,
              dataChanged: _vm.onDataChanged,
              error: _vm.onError,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.warning"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.warningPromptOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showWarningPrompt,
            callback: function ($$v) {
              _vm.showWarningPrompt = $$v
            },
            expression: "showWarningPrompt",
          },
        },
        [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t("task.import_warning", [_vm.warningTaskCount]))
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }