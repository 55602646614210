<template>
  <div>
    <!-- rebate selector -->
    <GenericSelectorModalForAdmin v-if="rebateSelectorShow"
      :show.sync="rebateSelectorShow" 
      :entityService="rebateUtil" 
      entity="REBATE"
      nonAdmin
      :preselected="rebateUuids"
      @ok="rebateSelectorOk"
      @cancel="rebateSelectorCancel"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';
import { rebateUtil } from '@/views/management/script/rebate';

export default Vue.extend({
  name: 'RebateEditor',
  components: {
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  data() {
    return {
      value: '',
      rebateSelectorShow: true,
      rebateUuids: [],
      rebateUnit: {},
      toConfirmRebates: [],
      cancelBeforeStart: false,
      highlightAllOnFocus: true,
      compactMode: false,
      hasChanged: false
    };
  },
  methods: {
    getValue() {
      if (this.compactMode) {
       const orgValue = this.params.value.single;
        const curValue = this.value;
        if (orgValue == null && curValue == null) {
          return this.params.value;
        } else if ((orgValue == null && curValue != null && curValue.length > 0) || orgValue != null && (orgValue.length != curValue.length)) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        let hasChanged = false;
        for (let i = 0, len = orgValue.length; i < len; i++) {
          const found = curValue.find(v => v.uuId == orgValue[i].uuId);
          if (found == null) {
            hasChanged = true;
            break;
          }
        }
        if (hasChanged) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.params.value;
      } else {
        return this.value;
      }
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      this.value = this.compactMode? objectClone(params.value.single) : params.value;
    },

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      if (this.params.validation) {
        return this.params.validation(this.params, this.value);
      }
      return this.value > 1000000;
    },
    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    rebateSelectorOk({ details }) {
      this.hasChanged = true;
      const newRebates = details;
      if (typeof this.value === 'string') {
        const rebates = [];
        let rebateList = null;
        if (this.params.context.componentParent != null && this.params.context.componentParent.rebates != null) {
          rebateList = this.params.context.componentParent.rebates;
        }
        newRebates.forEach(i => {
          if (rebateList != null) {
            rebateList[i.name] = { uuId: i.uuId };
          }
          rebates.push( `${i.name} (${i.rebate})` );
        });
      
        this.value = rebates.join(', ');
      }
      else {
        this.value = newRebates.map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});
      }
      
      this.closePicker();
    },
    rebateSelectorCancel() {
      this.closePicker();
    },
    getUuids() {
      if (typeof this.params.value === 'undefined') {
        this.rebateUuids = [];
        return;
      }
      else if (this.compactMode) {
        this.rebateUuids = this.params.value.single != null? this.params.value.single.map(i => i.uuId) : [];
        return;
      }
      else if (typeof this.params.value === 'object') {
        this.rebateUuids = this.params.value.map(i => i.uuId);
        return;
      }
      
      const value = this.params.value;
      const rebateArray = value.split('), ');
      this.rebateUuids = [];
      for (const rebate of rebateArray) {
        const matches = /(^.+?) \((\d+?)\)?$/.exec(rebate);
        if (matches !== null) {
          const fullName = matches[1];
          this.rebateUuids.push(this.params.context.componentParent.rebates[fullName].uuId);
        }
      }
    },
    rebateSelectorDeleted({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});
    }
  },

  created() {
    this.rebateUtil = rebateUtil;
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.setInitialState(this.params);
    this.getUuids();
    
  },
  beforeDestroy() {
    this.rebateUtil = null;
  },
  mounted() {
    Vue.nextTick(() => {
      // need to check if the input reference is still valid - if the edit was cancelled before it started there
      // wont be an editor component anymore
      if (this.$refs.input) {
        this.$refs.input.focus();
        if (this.highlightAllOnFocus) {
          this.$refs.input.select();

          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the caret
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = this.$refs.input.value
            ? this.$refs.input.value.length
            : 0;
          if (length > 0) {
            this.$refs.input.setSelectionRange(length, length);
          }
        }

        this.$refs.input.focus();
      }
    });
  }
})
</script>